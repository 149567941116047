// src/pages/Whitepaper.js
import React from 'react';
import styled from 'styled-components';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const WhitepaperSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 4rem 1rem;
  min-height: 100vh;

  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

const ContentWrapper = styled.div`
  max-width: 1000px;
  width: 100%;
  background-color: #FAB3B3;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-top: 5rem;

  @media (max-width: 768px) {
    padding: 1.5rem;
    margin-top: 3rem;
  }
`;

const PDFViewerWrapper = styled.div`
  height: 1100px; /* Set the desired height for the PDF viewer */
  overflow-y: auto; /* Allow vertical scrolling */
  border: 1px solid #ddd; /* Optional: Add a border for visual separation */

  @media (max-width: 768px) {
    height: 600px; /* Adjust height for smaller screens */
  }
`;

const Whitepaper = () => {
  return (
    <>
      <Navbar />
      <WhitepaperSection>
        <ContentWrapper>
          <h1>HavocHive Whitepaper v1.03</h1>
          <h4>Last Updated: August 22, 2024</h4><br />
          <PDFViewerWrapper>
            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.0.279/build/pdf.worker.min.js`}>
              <Viewer fileUrl="/whitepaper.pdf" />
            </Worker>
          </PDFViewerWrapper>
        </ContentWrapper>
      </WhitepaperSection>
      <Footer />
    </>
  );
};

export default Whitepaper;
