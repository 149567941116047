// src/components/HavocToken.js
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faGift, faChartLine, faVoteYea, faBurn, faCube, faCopy } from '@fortawesome/free-solid-svg-icons';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';

const HavocTokenSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff; /* White background for contrast */
  padding: 6rem 1rem; /* Increased padding for more space */
  margin-top: 2rem; /* Added margin to top */
  margin-bottom: 2rem; /* Added margin to bottom */
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  color: #333333;
  margin-bottom: 2rem;
  text-align: center;
`;

const HavocTokenWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  max-width: 1200px;
  width: 100%;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr); /* Adjust to 2 columns on medium screens */
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* Adjust to 1 column on smaller screens */
  }
`;

const TokenCard = styled.div`
  padding: 2.5rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 768px) {
    padding: 1.5rem; /* Adjust padding for smaller screens */
  }
`;

const IconWrapper = styled.div`
  font-size: 3.5rem;
  color: #333333; /* Black icons */
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    font-size: 2.5rem; /* Adjust icon size for smaller screens */
  }
`;

const CardTitle = styled.h3`
  font-size: 1.75rem;
  color: #333333;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 1.5rem; /* Adjust title size for smaller screens */
  }
`;

const CardDescription = styled.p`
  font-size: 1.125rem;
  color: #555555;
  line-height: 1.6;
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    font-size: 1rem; /* Adjust description size for smaller screens */
  }
`;

const TechnicalDetail = styled.p`
  font-size: 0.875rem;
  color: #1E1D1D;
  line-height: 1.4;
  margin-top: 0.5rem;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 0.75rem; /* Adjust technical detail size for smaller screens */
  }
`;

const HighlightedText = styled.span`
  color: #413D3D; /* Specific shade of red for highlights */
  font-weight: bold;
`;

const CalloutBox = styled.div`
  grid-column: span 3;
  padding: 2.5rem;
  background-color: #ff5757;
  color: #ffffff;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);

  @media (max-width: 768px) {
    grid-column: span 1; /* Adjust column span for smaller screens */
    padding: 1.5rem; /* Adjust padding for smaller screens */
  }
`;

const CalloutText = styled.p`
  font-size: 1.5rem;
  line-height: 1.7;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 1.25rem; /* Adjust text size for smaller screens */
  }
`;

const CTAButton = styled.a`
  display: inline-block;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: #ffffff;
  color: #ff5757;
  text-decoration: none;
  border-radius: 50px;
  box-shadow: 0px 0px 10px rgba(255, 87, 87, 0.3);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background-color: #e04e4e;
    box-shadow: 0px 0px 15px rgba(255, 87, 87, 0.5);
  }

  @media (max-width: 768px) {
    font-size: 0.9rem; /* Adjust button text size for smaller screens */
    padding: 0.5rem 1rem; /* Adjust padding for smaller screens */
  }
`;

const ChartContainer = styled.div`
  grid-column: span 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 3rem;

  @media (max-width: 768px) {
    grid-column: span 1;
    padding: 1.5rem;
  }
`;

const ChartRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 2rem;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const PieChartWrapper = styled.div`
  width: 30%;
  max-width: 300px;
  background-color: #000000;
  padding: 1.5rem;
  border-radius: 8px;

  @media (max-width: 1024px) {
    width: 100%;
    max-width: none;
  }
`;

const PieChartTitle = styled.h3`
  text-align: center;
  font-size: 1.25rem;
  margin-bottom: 1rem;
  color: #ffffff;
`;

const TableContainer = styled.div`
  width: 68%;
  font-size: 0.85rem;
  overflow-x: auto;

  @media (max-width: 1024px) {
    width: 100%;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  th, td {
    border: 1px solid #ddd;
    padding: 0.6rem;
    text-align: left;
  }

  th {
    background-color: #f9f9f9;
    font-weight: bold;
  }
`;

const TokenDetailsContainer = styled.div`
  margin-top: 1rem;
  text-align: left;
  color: #ffffff;
  font-size: 0.9rem;
`;

const TokenDetail = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

const DetailLabel = styled.span`
  font-weight: bold;
`;

const DetailValue = styled.span`
  color: #ff5757;
`;

const ContractAddress = styled.div`
  margin-top: 1rem;
  font-size: 0.8rem;
  word-break: break-all;
  
  .copy-container {
    display: flex;
    align-items: center;
    margin-top: 0.25rem;

    .copy-text {
      background-color: #1E1E1E;
      padding: 0.25rem 0.5rem;
      border-radius: 4px;
      margin-right: 0.5rem;
    }

    .copy-icon {
      cursor: pointer;
      font-size: 1rem;
      color: #ff5757;
      &:hover {
        color: #ffffff;
      }
    }
  }
`;

const TelegramLink = styled.a`
  display: block;
  margin-top: 1rem;
  color: #ff5757;
  text-decoration: none;
  font-weight: bold;
  
  &:hover {
    text-decoration: underline;
  }
`;

const tokenDistribution = [
  { name: 'App User Rewards', value: 30, color: '#ff9999' },
  { name: 'Ecosystem Growth', value: 35, color: '#66b3ff' },
  { name: 'Protocol Development', value: 15, color: '#99ff99' },
  { name: 'Marketing and Community', value: 10, color: '#ffcc99' },
  { name: 'Liquidity Provision', value: 5, color: '#c2c2f0' },
  { name: 'Private Sales', value: 5, color: '#f9f871' },
  { name: 'Governance Allocation', value: 2, color: '#fc7f9b' },
  { name: 'Community Airdrop', value: 1.5, color: '#7fb3d5' },
  { name: 'Strategic Reserves', value: 1.5, color: '#aed6f1' },
];

const milestoneData = [
  { name: 'Y1-Q1', milestone: 'Initial Development', value: 1, top: true },
  { name: 'Y1-Q2', milestone: 'Alpha Testing', value: 2, top: false },
  { name: 'Y1-Q3', milestone: 'Public Beta', value: 3, top: true },
  { name: 'Y1-Q4', milestone: 'Mainnet Launch', value: 4, top: false },
  { name: 'Y2-Q1', milestone: 'Staking Launch', value: 5, top: true },
  { name: 'Y2-Q2', milestone: 'Exchange Listings', value: 6, top: false },
  { name: 'Y2-Q3', milestone: 'Advanced Features', value: 7, top: true },
  { name: 'Y2-Q4', milestone: 'Ecosystem Grants', value: 8, top: false },
  { name: 'Y3-Q1', milestone: 'Expand Use Cases', value: 9, top: true },
  { name: 'Y3-Q2', milestone: 'International Expansion', value: 10, top: false },
  { name: 'Y3-Q3', milestone: 'Data Marketplace', value: 11, top: true },
  { name: 'Y3-Q4', milestone: 'Full Decentralization', value: 12, top: false },
];

const detailedMilestones = [
  {
    year: 'Year 1',
    quarters: [
      {
        quarter: 'Q1',
        title: 'Initial Development and Preparation',
        description: 'Develop iOS and Android apps (Alpha), launch website and community channels, finalize governance model.'
      },
      {
        quarter: 'Q2',
        title: 'Alpha Testing and Community Engagement',
        description: 'Conduct alpha testing, deploy vesting smart contracts, announce community airdrop, launch governance on Realms.'
      },
      {
        quarter: 'Q3',
        title: 'Public Beta and User Rewards Program',
        description: 'Launch public beta, initiate user rewards program, deploy governance token vesting contracts.'
      },
      {
        quarter: 'Q4',
        title: 'Mainnet Launch and Liquidity Provision',
        description: 'Launch mainnet apps, establish liquidity pools on DEXs, begin staking program development.'
      }
    ]
  },
  {
    year: 'Year 2',
    quarters: [
      {
        quarter: 'Q1',
        title: 'Staking Launch and Ecosystem Growth',
        description: 'Launch staking program, fund development grants, introduce new governance proposals.'
      },
      {
        quarter: 'Q2',
        title: 'Exchange Listings and Partnerships',
        description: 'Pursue CEX listings, form strategic partnerships, enhance user experience.'
      },
      {
        quarter: 'Q3',
        title: 'Advanced Features and Governance Maturity',
        description: 'Develop advanced app features, mature governance process, expand marketing campaigns.'
      },
      {
        quarter: 'Q4',
        title: 'Governance Treasury and Ecosystem Grants',
        description: 'Launch governance treasury management, initiate ecosystem grants program.'
      }
    ]
  },
  {
    year: 'Year 3',
    quarters: [
      {
        quarter: 'Q1',
        title: 'Expand Data Use Cases',
        description: 'Explore new data use cases, develop APIs and SDKs, continue protocol development.'
      },
      {
        quarter: 'Q2',
        title: 'Scaling and International Expansion',
        description: 'Scale operations, optimize performance, expand to new regions and languages.'
      },
      {
        quarter: 'Q3',
        title: 'Decentralized Data Marketplace',
        description: 'Develop decentralized data marketplace, launch new governance features.'
      },
      {
        quarter: 'Q4',
        title: 'Long-Term Sustainability',
        description: 'Focus on ecosystem sustainability, grow community, achieve full decentralization.'
      }
    ]
  }
];


const HavocToken = () => {
  const [sliderValue, setSliderValue] = useState(0);
  const timelineRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    if (timelineRef.current && containerRef.current) {
      const containerWidth = containerRef.current.clientWidth;
      const timelineWidth = timelineRef.current.scrollWidth;
      const maxTranslate = timelineWidth - containerWidth;
      const translate = (sliderValue / 100) * maxTranslate;
      timelineRef.current.style.transform = `translateX(-${translate}px)`;
    }
  }, [sliderValue]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText('5bY9kXt4kCEC1T5pfHTDAzknbmBoow5QmM8t2oEQuA7C');
    alert('Contract address copied to clipboard!');
  };

  const EarningsTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 2rem;
    font-size: 0.9rem;
  `;

  const TableHeader = styled.th`
    background-color: #ff5757;
    color: white;
    padding: 1rem;
    text-align: left;
  `;

  const TableRow = styled.tr`
    &:nth-child(even) {
      background-color: #f2f2f2;
    }
    &:nth-last-child(-n+2) {
      background-color: #ffe6e6;
      font-weight: bold;
    }
  `;

  const TableCell = styled.td`
    padding: 1rem;
    border-bottom: 1px solid #ddd;
  `;

  const ExplanationSection = styled.div`
    margin-top: 2rem;
  `;

  const ExplanationTitle = styled.h3`
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 1rem;
  `;

  const ExplanationText = styled.p`
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
    margin-bottom: 1rem;
  `;

  const earningsData = [
    {
      tier: "Tier 1: Basic",
      requirements: "- Contribute at least 1 data point per day.\n- Minimum activity: 5 days per month.",
      rewardRate: "10 HVH",
      bonusMultipliers: "None",
      additionalBenefits: "Access to basic app features."
    },
    {
      tier: "Tier 2: Standard",
      requirements: "- Contribute at least 5 data points per day.\n- Minimum activity: 15 days per month.\n- Hold at least 1,000 HVH tokens for 30 days.",
      rewardRate: "50 HVH",
      bonusMultipliers: "1.1x for consistent daily contributions (30 days).",
      additionalBenefits: "Access to app analytics and insights dashboard."
    },
    {
      tier: "Tier 3: Advanced",
      requirements: "- Contribute at least 10 data points per day.\n- Minimum activity: 25 days per month.\n- Hold at least 2,500 HVH tokens for 30 days.",
      rewardRate: "120 HVH",
      bonusMultipliers: "1.2x for consistent daily contributions (30 days).",
      additionalBenefits: "Early access to new app features and beta tests."
    },
    {
      tier: "Tier 4: Pro",
      requirements: "- Contribute at least 25 data points per day.\n- Minimum activity: 30 days per month.\n- Hold at least 5,000 HVH tokens for 30 days.",
      rewardRate: "300 HVH",
      bonusMultipliers: "1.5x for maintaining Pro status for 3 consecutive months.\n1.5x for holding 10,000 HVH for 30 days.",
      additionalBenefits: "Priority support and access to governance voting."
    },
    {
      tier: "Tier 5: Elite",
      requirements: "- Contribute at least 50 data points per day.\n- Minimum activity: 30 days per month.\n- Hold at least 15,000 HVH tokens for 30 days.\n- Stake at least 500 HVH tokens.",
      rewardRate: "700 HVH",
      bonusMultipliers: "2x for maintaining Elite status for 6 consecutive months.\n2x for holding 25,000 HVH for 30 days.",
      additionalBenefits: "VIP status, exclusive airdrops, and higher governance influence."
    },
    {
      tier: "Tier 6: Business",
      requirements: "- Hold a minimum of 100,000 HVH tokens for 30 days.\n- Contribute at least 100 data points per day.\n- Minimum activity: 30 days per month.",
      rewardRate: "2,500 HVH",
      bonusMultipliers: "3x for holding 200,000 HVH tokens for 30 days.",
      additionalBenefits: "Dedicated account manager, priority feature requests, and custom API access."
    },
    {
      tier: "Tier 7: Whale",
      requirements: "- Hold a minimum of 500,000 HVH tokens for 30 days.\n- Contribute at least 250 data points per day.\n- Minimum activity: 30 days per month.",
      rewardRate: "10,000 HVH",
      bonusMultipliers: "4x for holding 1,000,000 HVH tokens for 60 days.",
      additionalBenefits: "Exclusive governance privileges, access to private beta testing, and VIP community events."
    }
  ];

  return (
    <HavocTokenSection id="havoc-token">
      <SectionTitle>The Havoc Token</SectionTitle>

      <HavocTokenWrapper>
        {/* Token Distribution and Vesting Plan */}
        <ChartContainer>
          <SectionTitle>Token Distribution and Vesting Plan</SectionTitle>
          <ChartRow>
            <PieChartWrapper>
              <PieChartTitle>Token Distribution</PieChartTitle>
              <ResponsiveContainer width="100%" height={250}>
                <PieChart>
                  <Pie
                    data={tokenDistribution}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    innerRadius={60}
                    fill="#8884d8"
                    label
                  >
                    {tokenDistribution.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
              <TokenDetailsContainer>
                <TokenDetail>
                  <DetailLabel>Token Name:</DetailLabel>
                  <DetailValue>Havoc Hive</DetailValue>
                </TokenDetail>
                <TokenDetail>
                  <DetailLabel>Symbol:</DetailLabel>
                  <DetailValue>HVH</DetailValue>
                </TokenDetail>
                <TokenDetail>
                  <DetailLabel>Chain:</DetailLabel>
                  <DetailValue>Solana</DetailValue>
                </TokenDetail>
                <TokenDetail>
                  <DetailLabel>Fixed Supply:</DetailLabel>
                  <DetailValue>15,000,000,000 HVH</DetailValue>
                </TokenDetail>
                <ContractAddress>
                  <DetailLabel>Contract Address:</DetailLabel>
                  <div className="copy-container">
                    <span className="copy-text">5bY9kXt4kCEC1T5pfHTDAzknbmBoow5QmM8t2oEQuA7C</span>
                    <FontAwesomeIcon icon={faCopy} className="copy-icon" onClick={copyToClipboard} />
                  </div>
                </ContractAddress>
                <TelegramLink href="https://t.me/HavocHive" target="_blank" rel="noopener noreferrer">
                  Join our Telegram: t.me/HavocHive
                </TelegramLink>
              </TokenDetailsContainer>
            </PieChartWrapper>

            {/* Token Distribution Table */}
            <TableContainer>
              <table>
                <thead>
                  <tr>
                    <th>Allocation Category</th>
                    <th>Percentage of Total Supply</th>
                    <th>Amount (HVH)</th>
                    <th>TGE Unlock %</th>
                    <th>TGE Unlock Amount (HVH)</th>
                    <th>Cliff Period</th>
                    <th>Vesting Schedule</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>App User Rewards</td>
                    <td>30%</td>
                    <td>4,500,000,000</td>
                    <td>100%</td>
                    <td>4,500,000,000</td>
                    <td>No Cliff</td>
                    <td>Immediate availability; 75% in Rewards Treasury, 25% in Rewards Wallet for payouts.</td>
                  </tr>
                  <tr>
                    <td>Ecosystem Growth</td>
                    <td>35%</td>
                    <td>5,250,000,000</td>
                    <td>10%</td>
                    <td>525,000,000</td>
                    <td>6 months</td>
                    <td>Linear unlock over 3 years or milestone-based.</td>
                  </tr>
                  <tr>
                    <td>Protocol Development</td>
                    <td>15%</td>
                    <td>2,250,000,000</td>
                    <td>10%</td>
                    <td>225,000,000</td>
                    <td>6 months</td>
                    <td>Linear unlock over 2 years.</td>
                  </tr>
                  <tr>
                    <td>Marketing and Community</td>
                    <td>10%</td>
                    <td>1,500,000,000</td>
                    <td>20%</td>
                    <td>300,000,000</td>
                    <td>3 months</td>
                    <td>Linear unlock over 1 year.</td>
                  </tr>
                  <tr>
                    <td>Liquidity Provision</td>
                    <td>5%</td>
                    <td>750,000,000</td>
                    <td>100%</td>
                    <td>750,000,000</td>
                    <td>No Cliff</td>
                    <td>No vesting; available immediately.</td>
                  </tr>
                  <tr>
                    <td>Private Sales</td>
                    <td>5%</td>
                    <td>750,000,000</td>
                    <td>0%</td>
                    <td>0</td>
                    <td>6 months</td>
                    <td>Linear unlock over 2 years.</td>
                  </tr>
                  <tr>
                    <td>Governance Allocation</td>
                    <td>2%</td>
                    <td>300,000,000</td>
                    <td>0%</td>
                    <td>0</td>
                    <td>6 months</td>
                    <td>Linear unlock over 1 year.</td>
                  </tr>
                  <tr>
                    <td>Community Airdrop</td>
                    <td>1.5%</td>
                    <td>225,000,000</td>
                    <td>100%</td>
                    <td>225,000,000</td>
                    <td>No Cliff</td>
                    <td>Available immediately for claim.</td>
                  </tr>
                  <tr>
                    <td>Strategic Reserves</td>
                    <td>1.5%</td>
                    <td>225,000,000</td>
                    <td>0%</td>
                    <td>0</td>
                    <td>No Cliff</td>
                    <td>Reserved for future use or emergencies.</td>
                  </tr>
                </tbody>
              </table>
            </TableContainer>
          </ChartRow>
        </ChartContainer>

        {/* Project Milestones Timeline */}
        <ChartContainer>
          <SectionTitle>Project Milestones</SectionTitle>
          <TimelineContainer ref={containerRef}>
            <TimelineContent ref={timelineRef}>
              <TimelineLine />
              {milestoneData.map((item, index) => (
                <TimelineItem 
                  key={index} 
                  top={index % 2 === 0}
                  left={((index + 0.5) / milestoneData.length) * 100}
                >
                  <strong>{item.name}</strong>
                  <p>{item.milestone}</p>
                </TimelineItem>
              ))}
            </TimelineContent>
          </TimelineContainer>
          <SliderContainer>
            <Slider 
              type="range" 
              min="0" 
              max="100" 
              value={sliderValue} 
              onChange={(e) => setSliderValue(parseInt(e.target.value))}
            />
          </SliderContainer>
          <div style={{ display: 'flex', justifyContent: 'space-between', gap: '2rem' }}>
            {detailedMilestones.map((year, index) => (
              <YearCard key={index}>
                <YearTitle>{year.year}</YearTitle>
                {year.quarters.map((quarter, qIndex) => (
                  <QuarterContainer key={qIndex}>
                    <QuarterTitle>{quarter.quarter}: {quarter.title}</QuarterTitle>
                    <QuarterDescription>{quarter.description}</QuarterDescription>
                  </QuarterContainer>
                ))}
              </YearCard>
            ))}
          </div>
        </ChartContainer>

        {/* User Earnings Chart */}
        <ChartContainer>
          <SectionTitle>User Earnings Model</SectionTitle>
          <EarningsTable>
            <thead>
              <tr>
                <TableHeader>Tier</TableHeader>
                <TableHeader>Requirements</TableHeader>
                <TableHeader>Reward Rate (HVH per Day)</TableHeader>
                <TableHeader>Bonus Multipliers</TableHeader>
                <TableHeader>Additional Benefits</TableHeader>
              </tr>
            </thead>
            <tbody>
              {earningsData.map((tier, index) => (
                <TableRow key={index}>
                  <TableCell>{tier.tier}</TableCell>
                  <TableCell>{tier.requirements.split('\n').map((req, i) => <React.Fragment key={i}>{req}<br/></React.Fragment>)}</TableCell>
                  <TableCell>{tier.rewardRate}</TableCell>
                  <TableCell>{tier.bonusMultipliers.split('\n').map((bonus, i) => <React.Fragment key={i}>{bonus}<br/></React.Fragment>)}</TableCell>
                  <TableCell>{tier.additionalBenefits}</TableCell>
                </TableRow>
              ))}
            </tbody>
          </EarningsTable>

          <ExplanationSection>
            <ExplanationTitle>Explanation of the Combined Earnings Model</ExplanationTitle>
            <ExplanationText>
              <strong>Basic to Elite Tiers (1-5):</strong><br />
              • Focuses on varying levels of data contribution, activity, and HVH token holding requirements.<br />
              • Designed to engage a broad range of users, from casual participants to active contributors and investors.<br />
              • Includes multipliers for consistency and token holding to encourage long-term commitment and retention.
            </ExplanationText>
            <ExplanationText>
              <strong>Business and Whale Tiers (6-7):</strong><br />
              • Targeted at larger entities and major stakeholders who can significantly contribute to the platform's data pool and hold substantial amounts of HVH tokens.<br />
              • Offer substantial rewards and exclusive benefits to incentivize heavy investment and participation.<br />
              • Includes high multipliers for maintaining large HVH holdings over extended periods.
            </ExplanationText>
            <ExplanationTitle>Key Features of the Earnings Model</ExplanationTitle>
            <ExplanationText>
              • <strong>Reward Rates:</strong> Tailored to each tier's contribution level, ensuring fair compensation for participation.<br />
              • <strong>Bonus Multipliers:</strong> Encourage consistent engagement and long-term holding of HVH tokens, reducing token circulation and fostering community growth.<br />
              • <strong>Additional Benefits:</strong> Provide unique incentives like governance privileges, VIP status, access to exclusive features, and more, enhancing user satisfaction and loyalty.
            </ExplanationText>
          </ExplanationSection>
        </ChartContainer>

        {/* Token Card 1 */}
        <TokenCard>
          <IconWrapper>
            <FontAwesomeIcon icon={faDollarSign} />
          </IconWrapper>
          <CardTitle>Real Utility in the Digital World</CardTitle>
          <CardDescription>
            The Havoc Token is more than just a digital asset; it's a real utility token with significant value. Enterprises use Havoc Tokens to pay for our entropy services, gaining access to high-quality entropy at discounted rates.
          </CardDescription>
          <TechnicalDetail>
            Example: Secure your transactions while supporting a decentralized ecosystem with tangible benefits.
          </TechnicalDetail>
        </TokenCard>

        {/* Token Card 2 */}
        <TokenCard>
          <IconWrapper>
            <FontAwesomeIcon icon={faGift} />
          </IconWrapper>
          <CardTitle>Empowering Contributors</CardTitle>
          <CardDescription>
            For contributors, Havoc Tokens provide a seamless way to get rewarded for the data they share. Payments in Havoc Tokens are fast and efficient, thanks to the underlying Solana blockchain.
          </CardDescription>
          <TechnicalDetail>
            Example: Receive your rewards promptly, directly into your wallet, without friction or delays.
          </TechnicalDetail>
        </TokenCard>

        {/* Token Card 3 */}
        <TokenCard>
          <IconWrapper>
            <FontAwesomeIcon icon={faChartLine} />
          </IconWrapper>
          <CardTitle>Growing Demand, Growing Value</CardTitle>
          <CardDescription>
            As the demand for HavocHive's entropy services increases, so does the demand for Havoc Tokens. The value of the Havoc Token grows, benefiting all participants.
          </CardDescription>
          <TechnicalDetail>
            Example: A positive feedback loop that boosts the utility and value of the Havoc Token.
          </TechnicalDetail>
        </TokenCard>

        {/* Token Card 4 */}
        <TokenCard>
          <IconWrapper>
            <FontAwesomeIcon icon={faVoteYea} />
          </IconWrapper>
          <CardTitle>Staking and Governance</CardTitle>
          <CardDescription>
            Participate in the HavocHive ecosystem by staking tokens and voting on key decisions that shape the future of the platform.
          </CardDescription>
          <TechnicalDetail>
            Example: Influence the direction of HavocHive by casting your vote on critical governance issues.
          </TechnicalDetail>
        </TokenCard>

        {/* Token Card 5 */}
        <TokenCard>
          <IconWrapper>
            <FontAwesomeIcon icon={faBurn} />
          </IconWrapper>
          <CardTitle>Token Burns</CardTitle>
          <CardDescription>
            A portion of every transaction fee is burned, reducing the total supply of Havoc Tokens and increasing their scarcity over time.
          </CardDescription>
          <TechnicalDetail>
            Example: Benefit from a deflationary mechanism that enhances the long-term value of your tokens.
          </TechnicalDetail>
        </TokenCard>

        {/* Token Card 6 */}
        <TokenCard>
          <IconWrapper>
            <FontAwesomeIcon icon={faCube} />
          </IconWrapper>
          <CardTitle>Future Integrations</CardTitle>
          <CardDescription>
            Havoc Tokens will be integrated into additional platforms and services, expanding their utility and creating new opportunities for users.
          </CardDescription>
          <TechnicalDetail>
            Example: Explore new use cases and partnerships that broaden the horizons of Havoc Token utility.
          </TechnicalDetail>
        </TokenCard>

        <CalloutBox>
          <CalloutText>
            To learn more about how to maximize your rewards and benefits, read our detailed <HighlightedText>Whitepaper</HighlightedText>. Discover our plans for future integrations, token tranches, vesting schedules, and much more.
          </CalloutText>
          <CTAButton href="/whitepaper">Read the Whitepaper</CTAButton>
        </CalloutBox>
      </HavocTokenWrapper>
    </HavocTokenSection>
  );
};

const TimelineContainer = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  margin-bottom: 3rem;
  overflow: hidden;
`;

const TimelineContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 240%;
  transition: transform 0.3s ease;
`;

const TimelineLine = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 3px;
  background-color: #ff5757;
`;

const TimelineItem = styled.div`
  position: absolute;
  width: 200px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  text-align: center;
  transform: translateX(-50%);
  font-size: 1rem;

  ${props => props.top ? 'bottom: 55%;' : 'top: 55%;'}
  left: ${props => props.left}%;

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    width: 2px;
    height: 20px;
    background-color: #ff5757;
    ${props => props.top ? 'bottom: -20px;' : 'top: -20px;'}
  }

  strong {
    display: block;
    margin-bottom: 5px;
    color: #ff5757;
  }

  p {
    margin: 0;
    font-size: 0.9rem;
  }
`;

const SliderContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const Slider = styled.input`
  width: 100%;
  -webkit-appearance: none;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
  border-radius: 12px;
  height: 8px;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: #ff5757;
    cursor: pointer;
    border-radius: 50%;
  }

  &::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: #ff5757;
    cursor: pointer;
    border-radius: 50%;
  }
`;

const YearCard = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  padding: 1.5rem;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
`;

const YearTitle = styled.h3`
  font-size: 1.5rem;
  color: #ff5757;
  margin-bottom: 1rem;
  text-align: center;
`;

const QuarterContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const QuarterTitle = styled.h4`
  font-size: 1.25rem;
  color: #333333;
  margin-bottom: 0.5rem;
`;

const QuarterDescription = styled.p`
  font-size: 0.9rem;
  color: #666666;
  margin-bottom: 1rem;
`;

export default HavocToken;
