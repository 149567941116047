// src/components/Footer.js
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const FooterSection = styled.footer`
  background-color: #000000; /* Dark background for contrast */
  color: #ffffff; /* White text for readability */
  padding: 3rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px; /* Set max-width to 1200px for alignment */
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
  }
`;

const LogoSection = styled.div`
  margin-bottom: 2rem;
  flex: 1; /* Allows the logo to take up more space */

  @media (min-width: 768px) {
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Logo = styled.img`
  height: 8.5rem; /* Increase the height of the logo */
  margin-bottom: 1rem;
`;

const Slogan = styled.p`
  font-size: 1.25rem;
  color: #ff5757; /* Havoc red for emphasis */
  margin-top: 2rem; /* Add some space between the navigation and slogan */
  text-align: center;
  width: 100%; /* Ensure it spans the full width of the footer */
`;

const NavSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
    flex: 2; /* Allows the navigation to take up more space */
  }
`;

const NavColumn = styled.div`
  margin: 1rem;

  @media (min-width: 768px) {
    margin: 0 2rem;
  }
`;

const NavTitle = styled.h4`
  font-size: 1.25rem;
  color: #ff5757; /* Havoc red for emphasis */
  margin-bottom: 1rem;
`;

const NavLink = styled(Link)` /* Use Link component for navigation */
  display: block;
  color: #ffffff;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: #ff5757; /* Havoc red on hover */
  }
`;

const Copyright = styled.div`
  font-size: 0.875rem;
  color: #aaaaaa;
  margin-top: 2rem;
  text-align: center;
`;

const DisclaimerSection = styled.div`
  background-color: #1a1a1a;
  padding: 1.5rem;
  border-radius: 8px;
  margin-top: 2rem;
  max-width: 1200px;
  width: 90%;
`;

const DisclaimerTitle = styled.h5`
  color: #ff5757;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
`;

const DisclaimerText = styled.p`
  color: #cccccc;
  font-size: 0.9rem;
  line-height: 1.4;
`;

const Footer = () => {
  return (
    <FooterSection>
      <FooterContent>
        <LogoSection>
          <Logo src="/HavocHivelogo.svg" alt="HavocHive Logo" />
        </LogoSection>

        <NavSection>
          <NavColumn>
            <NavTitle>Company</NavTitle>
            <NavLink to="#">About Us</NavLink>
            <NavLink to="#">Case Studies</NavLink>
            <NavLink to="/whitepaper">Whitepaper</NavLink>
            <NavLink to="#">Articles</NavLink>
          </NavColumn>

          <NavColumn>
            <NavTitle>Resources</NavTitle>
            <NavLink to="/privacy-policy">Privacy Policy</NavLink>
            <NavLink to="/disclaimer">Disclaimer</NavLink>
            <NavLink to="/terms-of-use">Terms of Use</NavLink>
            <NavLink to="#">Research</NavLink>
          </NavColumn>

          <NavColumn>
            <NavTitle>Connect</NavTitle>
            <NavLink to="https://solscan.io/token/5bY9kXt4kCEC1T5pfHTDAzknbmBoow5QmM8t2oEQuA7C">Solscan</NavLink>
            <NavLink to="https://x.com/HavocHiveHVH">Twitter (X)</NavLink>
            <NavLink to="#">Contact Us</NavLink>
            <NavLink to="#">Discord</NavLink>
          </NavColumn>
        </NavSection>
      </FooterContent>

      <Slogan>Harnessing Public Chaos to Secure the World.</Slogan> {/* Move the slogan here */}

      <DisclaimerSection>
        <DisclaimerTitle>Disclaimer</DisclaimerTitle>
        <DisclaimerText>
          The information provided on this website does not constitute investment advice, financial advice, trading advice, or any other form of advice. Investing in any cryptocurrency, including HVH tokens, is highly speculative and involves significant risks, including the potential loss of all value. HVH is not a registered security with the U.S. Securities and Exchange Commission (SEC) or any other securities agency, and we do not represent it as such. You can earn HVH tokens without purchasing them by anonymously contributing high-quality entropy data through your mobile device.
        </DisclaimerText>
      </DisclaimerSection>

      <Copyright>
        &copy; {new Date().getFullYear()} HavocHive. All rights reserved.
      </Copyright>
    </FooterSection>
  );
};

export default Footer;
