import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';

const NavbarContainer = styled.nav`
  width: 100%;
  background: linear-gradient(to bottom, #ffffff, #f8f8f8);
  border-bottom: 1px solid #eeeeee;
  padding: 1rem 2rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const NavContent = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NavList = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
    background-color: #ffffff;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 1rem 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const NavItem = styled.li`
  margin: 0 1.5rem;
  position: relative;

  &:hover > ul {
    opacity: 1;
    visibility: visible;
  }

  @media (max-width: 768px) {
    margin: 1rem 0;
  }
`;

const NavLink = styled.a`
  text-decoration: none;
  color: #333333;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 0;
  cursor: pointer;
  white-space: nowrap; /* Prevent text wrapping */
  transition: color 0.3s ease, background-color 0.3s ease;

  &:hover {
    color: #ff5757;
    background-color: #f0f0f0;
    border-radius: 4px;
  }

  &.active {
    border-bottom: 2px solid #ff5757;
  }
`;

const DropdownMenu = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  list-style: none;
  padding: 0.5rem 0;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #eeeeee;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  min-width: 200px; /* Ensure enough width to prevent wrapping */

  @media (max-width: 768px) {
    position: static;
    padding: 0;
    border: none;
    box-shadow: none;
    opacity: 1;
    visibility: visible;
  }
`;

const DropdownItem = styled.li`
  padding: 0.5rem 1rem; /* Provide padding to the dropdown items */
  white-space: nowrap; /* Prevent text wrapping */

  @media (max-width: 768px) {
    margin: 1rem 0;
  }
`;

const SolscanLogo = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 1rem;
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.7;
  }
`;

const TwitterIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 0.5rem;
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.7;
  }
`;
const TelegramIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 0.5rem;
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.7;
  }
`;

const LoginButton = styled(motion.button)`
  padding: 0.5rem 1.5rem;
  font-size: 0.9rem;
  background-color: #ff5757;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #e04e4e;
    transform: scale(1.05);
  }
`;

const LogoLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-right: 1.5rem;
`;

const BrandLogo = styled.img`
  height: 40px;
  vertical-align: middle;
  border-bottom: 2px solid #ff5757;
`;

const BurgerMenu = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    cursor: pointer;
  }
`;

const Navbar = () => {
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  const navItems = [
    { name: 'For Contributors', id: 'for-contributors' },
    { name: 'For Enterprises', id: 'for-enterprises' },
    { name: 'Havoc Token', id: 'havoc-token' }
  ];

  const dropdownItems = [
    { name: 'How It Works', id: 'how-it-works' },
    { name: 'Why HavocHive', id: 'why-havoc' },
    { name: 'Use Cases', id: 'use-cases' }
  ];

  return (
    <NavbarContainer>
      <NavContent>
        <LogoLink href="https://HavocHive.com">
          <BrandLogo src="/HavocHivelogo.svg" alt="HavocHive Logo" />
        </LogoLink>
        <BurgerMenu onClick={toggleMenu}>
          {isOpen ? <FaTimes size={24} color="#ff5757" /> : <FaBars size={24} color="#333" />}
        </BurgerMenu>
        <NavList isOpen={isOpen}>
          <NavItem>
            <NavLink href="#" onClick={toggleMenu}>
              About HavocHive
            </NavLink>
            <DropdownMenu>
              {dropdownItems.map((item, index) => (
                <DropdownItem key={index}>
                  {isHomePage ? (
                    <NavLink
                      as={ScrollLink}
                      to={item.id}
                      spy={true}
                      smooth={true}
                      duration={500}
                      onClick={toggleMenu}
                    >
                      {item.name}
                    </NavLink>
                  ) : (
                    <NavLink as={RouterLink} to="/" onClick={toggleMenu}>
                      {item.name}
                    </NavLink>
                  )}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </NavItem>
          {navItems.map((item, index) => (
            <NavItem key={index}>
              {isHomePage ? (
                <NavLink
                  as={ScrollLink}
                  to={item.id}
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={toggleMenu}
                >
                  {item.name}
                </NavLink>
              ) : (
                <NavLink as={RouterLink} to="/" onClick={toggleMenu}>
                  {item.name}
                </NavLink>
              )}
            </NavItem>
          ))}
          <NavItem>
            <a href="https://x.com/HavocHiveHVH" onClick={toggleMenu}>
              <TwitterIcon src="/x.png" alt="Havoc Hive Twitter" />
            </a>
          </NavItem>
          <NavItem>
            <a href="https://t.me/HavocHive" onClick={toggleMenu}>
              <TelegramIcon src="/telegram.png" alt="Havoc Hive Telegram" />
            </a>
          </NavItem>
          <NavItem>
            <a href="https://solscan.io/token/5bY9kXt4kCEC1T5pfHTDAzknbmBoow5QmM8t2oEQuA7C" onClick={toggleMenu}>
              <SolscanLogo src="/solscan.png" alt="Havoc Hive HVH Token on Solscan" />
            </a>
          </NavItem>
          <NavItem>
            <RouterLink to="/login" onClick={toggleMenu}>
              <LoginButton whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                Login
              </LoginButton>
            </RouterLink>
          </NavItem>
        </NavList>
      </NavContent>
    </NavbarContainer>
  );
};

export default Navbar;
