// src/components/Hero.js
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Navbar from './Navbar';  // Import the Navbar component

const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Full viewport height */
  background-color: #ffffff; /* White background */
  color: ${({ theme }) => theme.colors.text};
  text-align: center;
  position: relative;
  overflow: hidden;
  padding-top: 100px; /* Increased padding to avoid overlap with navbar */
  padding-bottom: 30px; /* Added padding at the bottom */
  box-sizing: border-box;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 1200px; /* Consistent max-width for all sections */
  padding: 0 1rem;
  box-sizing: border-box;
`;

const BrandWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const BrandLogo = styled.img`
  height: 7rem; /* Reduced height for better scaling */
  margin-right: 0.5rem;

  @media (max-width: 768px) {
    height: 5rem; /* Adjust logo size for smaller screens */
    margin-right: 0;
    margin-bottom: 1rem;
  }
`;

const BrandName = styled.div`
  font-size: 5rem; /* Reduced size for the brand name */
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  display: flex;
  opacity: 0;
  transform: translateY(-20px);
  animation: fadeInUp 1s forwards;

  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (max-width: 768px) {
    font-size: 3.5rem; /* Adjust font size for smaller screens */
    text-align: center;
  }
`;

const HavocText = styled(motion.span)`
  color: #ff5757; /* Red for "Havoc" */
`;

const HiveText = styled(motion.span)`
  color: #000000; /* Black for "Hive" */
`;

const Headline = styled(motion.h2)`
  font-size: 2.5rem; /* Smaller size */
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  color: #333333; /* Dark grey for headline */
  margin-bottom: 0.25rem; /* Less margin for spacing */
  opacity: 0;
  transform: translateY(-20px);
  animation: fadeInUp 1.2s forwards;

  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (max-width: 768px) {
    font-size: 2rem; /* Adjust size for mobile */
    padding: 0.5rem;
    
  }
`;

const Subheadline = styled.p`
  font-size: 1.4rem; /* Smaller size */
  margin-bottom: 1.5rem; /* Reduced margin */
  color: #555555; /* Medium grey for subheadline */

  @media (max-width: 768px) {
    font-size: 1.2rem; /* Adjust size for mobile */
    padding: 1rem;
  }
`;

const AnalyticsWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 1.5rem; /* Adjusted margin */
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.05);

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 0.5rem; /* Reduced padding for smaller screens */
  }
`;

const MetricBox = styled(motion.div)`
  flex: 1;
  margin: 0.25rem;
  padding: 0.5rem 1rem;
  background-color: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-left: 4px solid ${({ theme }) => theme.colors.primary};
  font-family: 'Courier New', monospace;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 768px) {
    margin-bottom: 1rem;
    width: 90%; /* Adjust width for mobile */
  }
`;

const MetricLabel = styled.h4`
  font-size: 0.9rem; /* Smaller font size */
  color: #555555;
  margin-bottom: 0.25rem;

  @media (max-width: 768px) {
    font-size: 0.8rem; /* Adjust font size for mobile */
  }
`;

const MetricValue = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};

  @media (max-width: 768px) {
    font-size: 1rem; /* Adjust font size for mobile */
  }
`;

const CTAWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 1.5rem;
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.05);

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 0.5rem;
  }
`;

const CTABox = styled.div`
  flex: 1;
  padding: 0.75rem;
  text-align: center;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  margin: 0 0.2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  @media (max-width: 768px) {
    width: 90%;
    margin-bottom: 1rem;
  }
`;

const CTATitle = styled.h3`
  font-size: 1.6rem;
  color: #333333;
  margin-bottom: 0.75rem;

  @media (max-width: 768px) {
    font-size: 1.4rem;
  }
`;

const CTAText = styled.p`
  font-size: 1.2rem;
  color: #555555;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const CTAButton = styled(motion.button)`
  padding: 0.6rem 1.2rem;
  font-size: 0.9rem;
  background-color: #ff5757;
  color: #ffffff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  outline: none;
  box-shadow: 0px 0px 10px rgba(255, 87, 87, 0.3);

  &:hover {
    background-color: #e04e4e;
    box-shadow: 0px 0px 15px rgba(255, 87, 87, 0.5);
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
  }
`;

const AppLinks = styled.div`
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: #555555;
  display: flex;
  justify-content: center;
  align-items: center;

  & > a {
    margin: 0 0.5rem;
    text-decoration: none;
    color: #ff5757;
    font-weight: bold;

    img {
      width: 90px; /* Reduced width for icons */
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px; /* Adds spacing between icons on smaller screens */
  }
`;

const NISTText = styled.p`
  font-size: 1rem;
  color: #333333;
  text-align: center;
  margin-top: 1rem;
  padding: 1rem;
  width: 100%;
  max-width: 1200px;
  line-height: 1.5;
  overflow-wrap: break-word;
  word-break: break-word;
  margin-bottom: 20px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    font-size: 0.9rem; /* Adjust font size for mobile */
    padding: 0.5rem;
  }

  span {
    color: #ff5757;
  }
`;

const Hero = () => {
  const [dataPool, setDataPool] = useState(2987072);
  const [entropyStreams, setEntropyStreams] = useState(57976);
  const [drbsGenerated, setDrbsGenerated] = useState(10765);
  const [trnsGenerated, setTrnsGenerated] = useState(10765);

  useEffect(() => {
    const interval = setInterval(() => {
      setDataPool((prev) => prev + Math.floor(Math.random() * 100));
      setEntropyStreams((prev) => prev + Math.floor(Math.random() * 10));
      setDrbsGenerated((prev) => prev + Math.floor(Math.random() * 5));
      setTrnsGenerated((prev) => prev + Math.floor(Math.random() * 7));
    }, 2000); // Update every 2 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Navbar /> {/* Add the Navbar component here */}
      <HeroSection id="hero">
        <BrandWrapper>
          <BrandLogo src="/HavocHivelogo.svg" alt="HavocHive Logo" />
          <BrandName>
            <HavocText>Havoc</HavocText>
            <HiveText>Hive</HiveText>
          </BrandName>
        </BrandWrapper>
        <Headline>Harnessing Public Chaos to Secure the World.</Headline>
        <Subheadline>
          Delivering High Quality and Scalable Entropy for Enterprise-Grade
          Security.
        </Subheadline>

        <AnalyticsWrapper id="analytics">
          <MetricBox>
            <MetricLabel>Public Chaos</MetricLabel>
            <MetricValue>{dataPool.toLocaleString()}</MetricValue>
          </MetricBox>
          <MetricBox>
            <MetricLabel>Entropy Consumed</MetricLabel>
            <MetricValue>{entropyStreams.toLocaleString()}</MetricValue>
          </MetricBox>
          <MetricBox>
            <MetricLabel>DRBs Generated</MetricLabel>
            <MetricValue>{drbsGenerated.toLocaleString()}</MetricValue>
          </MetricBox>
          <MetricBox>
            <MetricLabel>TRNs Generated</MetricLabel>
            <MetricValue>{trnsGenerated.toLocaleString()}</MetricValue>
          </MetricBox>
        </AnalyticsWrapper>

        <CTAWrapper id="cta">
          <CTABox>
            <CTATitle>For Enterprises</CTATitle>
            <CTAText>
              Discover how HavocHive can provide your enterprise with the
              highest quality entropy and secure TRNG solutions in a scalable,
              easy to consume form factor.
            </CTAText>
            <CTAButton whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              Get Started Here
            </CTAButton>
          </CTABox>

          <CTABox>
            <CTATitle>For Contributors</CTATitle>
            <CTAText>
              Earn rewards by simply contributing data through the HavocHive app
              - Claim Havoc Tokens straight to your Solana wallet. Your chaos,
              secures the world! (IN DEVELOPMENT)
            </CTAText>
            <AppLinks>
              <a href="https://havochive.com">
                <img src="/ios.png" alt="Download on the App Store" />
              </a>
              <a href="https://havochive.com">
                <img src="/android.png" alt="Get it on Google Play" />
              </a>
            </AppLinks>
          </CTABox>
        </CTAWrapper>

        <NISTText>
          Entropy passes <span>NIST SP800-90B</span> tests for IID and Non-IID
          sources. RNG passes <span>NIST SP800-22</span> battery of tests.
        </NISTText>
      </HeroSection>
    </>
  );
};

export default Hero;
