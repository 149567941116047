// src/pages/TermsOfUse.js
import React from 'react';
import Navbar from '../components/Navbar'; // Import the Navbar component
import Footer from '../components/Footer'; // Import the Footer component
import styled from 'styled-components';

const PageContainer = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8f8;
  margin-top: 4rem; /* Add margin-top to provide spacing from the Navbar */

  @media (max-width: 768px) {
    padding: 1.5rem;
    margin-top: 2rem; /* Adjust margin-top for smaller screens */
  }
`;

const Card = styled.div`
  max-width: 1000px;
  width: 100%;
  background-color: #ffffff;
  padding: 3rem;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: justify;

  @media (max-width: 768px) {
    padding: 2rem; /* Reduce padding for smaller screens */
  }
`;

const SectionTitle = styled.h1`
  font-size: 2.5rem;
  color: #333333;
  margin-bottom: 1rem;
  text-align: center; /* Center the title */

  @media (max-width: 768px) {
    font-size: 2rem; /* Reduce font size for smaller screens */
  }
`;

const Paragraph = styled.p`
  font-size: 1rem;
  color: #555555;
  line-height: 1.7;
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    font-size: 0.9rem; /* Reduce font size for smaller screens */
  }
`;

const TermsOfUse = () => {
  return (
    <>
      <Navbar />
      <PageContainer>
        <Card>
          <SectionTitle>Terms of Use</SectionTitle>
          <Paragraph>
            These Terms of Use govern your access to and use of HavocHive's services.
            By using our services, you agree to comply with and be bound by these terms.
          </Paragraph>
          {/* Add more content here */}
        </Card>
      </PageContainer>
      <Footer />
    </>
  );
};

export default TermsOfUse;
