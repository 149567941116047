// src/components/UseCases.js
import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faServer, faShieldAlt, 
  faKey, faBrain, faWallet, faUserShield, 
  faClipboardCheck, faIdBadge,
  faCar, faBoxes, faTree, faFilm, faPlug, faSignal, faRocket, faGraduationCap, faBriefcase, faGamepad
} from '@fortawesome/free-solid-svg-icons';

const UseCasesSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8f8; /* Light grey background for contrast */
  padding: 4rem 1rem;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  color: #333333;
  margin-bottom: 2rem;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 2rem; /* Smaller title size on mobile */
  }
`;

const UseCasesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  max-width: 1200px;
  width: 100%;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr); /* Two columns on medium screens */
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* Single column on small screens */
  }
`;

const UseCaseCard = styled.div`
  padding: 2.5rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 768px) {
    padding: 1.5rem; /* Reduce padding on small screens */
  }
`;

const IconWrapper = styled.div`
  font-size: 3.5rem;
  color: #ff5757; /* Reddish color for icons */
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    font-size: 2.5rem; /* Smaller icons on mobile */
  }
`;

const CardTitle = styled.h3`
  font-size: 1.75rem;
  color: #333333;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 1.5rem; /* Smaller title on mobile */
  }
`;

const CardDescription = styled.p`
  font-size: 1.125rem;
  color: #555555;
  line-height: 1.6;
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    font-size: 1rem; /* Smaller description on mobile */
  }
`;

const TechnicalDetail = styled.p`
  font-size: 0.875rem;
  color: #777777;
  line-height: 1.4;
  margin-top: 0.5rem;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 0.8rem; /* Smaller text on mobile */
  }
`;

const CalloutBox = styled.div`
  grid-column: span 3;
  padding: 2.5rem;
  background-color: #ff5757;
  color: #ffffff;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);

  @media (max-width: 768px) {
    grid-column: span 1; /* Adjust to full width on smaller screens */
  }
`;

const CalloutText = styled.p`
  font-size: 1.5rem;
  line-height: 1.7;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 1.25rem; /* Smaller text on mobile */
  }
`;

const CTAButton = styled.a`
  display: inline-block;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: #ffffff;
  color: #ff5757;
  text-decoration: none;
  border-radius: 50px;
  box-shadow: 0px 0px 10px rgba(255, 87, 87, 0.3);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background-color: #e04e4e;
    box-shadow: 0px 0px 15px rgba(255, 87, 87, 0.5);
  }

  @media (max-width: 768px) {
    font-size: 0.9rem; /* Smaller button text on mobile */
  }
`;

const UseCases = () => {
  return (
    <UseCasesSection id="use-cases">
      <SectionTitle>Comprehensive Use Cases</SectionTitle>
      <UseCasesWrapper>
        <UseCaseCard>
          <IconWrapper>
            <FontAwesomeIcon icon={faKey} />
          </IconWrapper>
          <CardTitle>Cryptography</CardTitle>
          <CardDescription>
            Generate secure cryptographic keys, nonces, and certificates for encryption and secure communications.
          </CardDescription>
          <TechnicalDetail>
            Example: Generate AES-256, RSA-2048, and elliptic curve keys to secure sensitive data.
          </TechnicalDetail>
        </UseCaseCard>

        <UseCaseCard>
          <IconWrapper>
            <FontAwesomeIcon icon={faShieldAlt} />
          </IconWrapper>
          <CardTitle>Blockchain Applications</CardTitle>
          <CardDescription>
            Provide secure randomness for blockchain operations, such as validator selection, smart contracts, and NFT minting.
          </CardDescription>
          <TechnicalDetail>
            Example: Ensure fairness in PoS validator selection and provide secure RNG for smart contract-based gaming.
          </TechnicalDetail>
        </UseCaseCard>

        <UseCaseCard>
          <IconWrapper>
            <FontAwesomeIcon icon={faServer} />
          </IconWrapper>
          <CardTitle>Enterprise Solutions</CardTitle>
          <CardDescription>
            Enhance enterprise security with secure communications, random sampling, and robust password management systems.
          </CardDescription>
          <TechnicalDetail>
            Example: Generate strong, random passwords for enterprise password managers and secure communication channels.
          </TechnicalDetail>
        </UseCaseCard>

        <UseCaseCard>
          <IconWrapper>
            <FontAwesomeIcon icon={faBrain} />
          </IconWrapper>
          <CardTitle>Artificial Intelligence and Machine Learning</CardTitle>
          <CardDescription>
            Improve AI/ML models with randomized data augmentation, stochastic algorithms, and privacy-preserving techniques.
          </CardDescription>
          <TechnicalDetail>
            Example: Use entropy in differential privacy to ensure AI models do not leak sensitive training data.
          </TechnicalDetail>
        </UseCaseCard>

        <UseCaseCard>
          <IconWrapper>
            <FontAwesomeIcon icon={faWallet} />
          </IconWrapper>
          <CardTitle>Finance and Trading</CardTitle>
          <CardDescription>
            Secure financial transactions, prevent fraud, and protect digital identities by integrating robust randomness.
          </CardDescription>
          <TechnicalDetail>
            Example: Use TRNG to add randomness to algorithmic trading strategies to prevent pattern recognition by competitors.
          </TechnicalDetail>
        </UseCaseCard>

        <UseCaseCard>
          <IconWrapper>
            <FontAwesomeIcon icon={faUserShield} />
          </IconWrapper>
          <CardTitle>Cybersecurity</CardTitle>
          <CardDescription>
            Enhance secure boot processes, intrusion detection systems, and zero-trust architectures with high-quality entropy.
          </CardDescription>
          <TechnicalDetail>
            Example: Randomize access tokens and session keys to strengthen the security of Zero Trust networks.
          </TechnicalDetail>
        </UseCaseCard>

        <UseCaseCard>
          <IconWrapper>
            <FontAwesomeIcon icon={faClipboardCheck} />
          </IconWrapper>
          <CardTitle>Healthcare and Medical Research</CardTitle>
          <CardDescription>
            Use high-quality randomness for clinical trials, data anonymization, and medical imaging.
          </CardDescription>
          <TechnicalDetail>
            Example: Apply TRNG in clinical trials for random assignment of participants to treatment arms.
          </TechnicalDetail>
        </UseCaseCard>

        <UseCaseCard>
          <IconWrapper>
            <FontAwesomeIcon icon={faCar} />
          </IconWrapper>
          <CardTitle>Automotive and Autonomous Systems</CardTitle>
          <CardDescription>
            Secure V2X communication, enhance autonomous vehicle decision-making, and protect in-car networks.
          </CardDescription>
          <TechnicalDetail>
            Example: Use entropy for generating session keys for secure V2X communication.
          </TechnicalDetail>
        </UseCaseCard>

        <UseCaseCard>
          <IconWrapper>
            <FontAwesomeIcon icon={faBoxes} />
          </IconWrapper>
          <CardTitle>Supply Chain and Logistics</CardTitle>
          <CardDescription>
            Secure inventory management, shipment routing, and blockchain-based supply chain solutions.
          </CardDescription>
          <TechnicalDetail>
            Example: Use entropy to randomize inventory audits and optimize shipment routes.
          </TechnicalDetail>
        </UseCaseCard>

        <UseCaseCard>
          <IconWrapper>
            <FontAwesomeIcon icon={faTree} />
          </IconWrapper>
          <CardTitle>Environmental Monitoring and Smart Cities</CardTitle>
          <CardDescription>
            Authenticate sensor data, apply randomized sampling, and optimize dynamic traffic management.
          </CardDescription>
          <TechnicalDetail>
            Example: Use entropy for secure cryptographic keys in environmental sensors.
          </TechnicalDetail>
        </UseCaseCard>

        <UseCaseCard>
          <IconWrapper>
            <FontAwesomeIcon icon={faFilm} />
          </IconWrapper>
          <CardTitle>Media and Digital Content</CardTitle>
          <CardDescription>
            Protect digital rights, randomize content distribution, and enhance ad targeting.
          </CardDescription>
          <TechnicalDetail>
            Example: Use entropy for secure DRM and randomized ad targeting algorithms.
          </TechnicalDetail>
        </UseCaseCard>

        <UseCaseCard>
          <IconWrapper>
            <FontAwesomeIcon icon={faPlug} />
          </IconWrapper>
          <CardTitle>Energy and Utilities</CardTitle>
          <CardDescription>
            Secure smart grid communication, optimize load balancing, and forecast renewable energy outputs.
          </CardDescription>
          <TechnicalDetail>
            Example: Use TRNG for secure communication in smart grid networks.
          </TechnicalDetail>
        </UseCaseCard>

        <UseCaseCard>
          <IconWrapper>
            <FontAwesomeIcon icon={faSignal} />
          </IconWrapper>
          <CardTitle>Telecommunications</CardTitle>
          <CardDescription>
            Secure communication channels, optimize network resource allocation, and detect fraud.
          </CardDescription>
          <TechnicalDetail>
            Example: Apply randomness in algorithms for detecting unauthorized network access.
          </TechnicalDetail>
        </UseCaseCard>

        <UseCaseCard>
          <IconWrapper>
            <FontAwesomeIcon icon={faRocket} />
          </IconWrapper>
          <CardTitle>Space and Aerospace</CardTitle>
          <CardDescription>
            Secure satellite communication, enhance navigation systems, and simulate unpredictable space environments.
          </CardDescription>
          <TechnicalDetail>
            Example: Use entropy to secure communication between ground stations and satellites.
          </TechnicalDetail>
        </UseCaseCard>

        <UseCaseCard>
          <IconWrapper>
            <FontAwesomeIcon icon={faGraduationCap} />
          </IconWrapper>
          <CardTitle>Education and Research</CardTitle>
          <CardDescription>
            Ensure fairness in examination systems, randomize research simulations, and sample surveys accurately.
          </CardDescription>
          <TechnicalDetail>
            Example: Use TRNG to generate unique examination questions.
          </TechnicalDetail>
        </UseCaseCard>

        <UseCaseCard>
          <IconWrapper>
            <FontAwesomeIcon icon={faBriefcase} />
          </IconWrapper>
          <CardTitle>Insurance and Risk Management</CardTitle>
          <CardDescription>
            Optimize underwriting models, detect fraud, and run actuarial simulations with high-quality entropy.
          </CardDescription>
          <TechnicalDetail>
            Example: Apply randomness in risk modeling algorithms to assess policyholder risk more accurately.
          </TechnicalDetail>
        </UseCaseCard>

        <UseCaseCard>
          <IconWrapper>
            <FontAwesomeIcon icon={faGamepad} />
          </IconWrapper>
          <CardTitle>Entertainment and Gambling</CardTitle>
          <CardDescription>
            Ensure fair and verifiable outcomes for lotteries, sports betting, and online games.
          </CardDescription>
          <TechnicalDetail>
            Example: Use TRNG for secure, random outcomes in virtual reality games.
          </TechnicalDetail>
        </UseCaseCard>

        <UseCaseCard>
          <IconWrapper>
            <FontAwesomeIcon icon={faIdBadge} />
          </IconWrapper>
          <CardTitle>Digital Identity Verification</CardTitle>
          <CardDescription>
            Utilize high-quality entropy for secure and reliable digital identity verification processes.
          </CardDescription>
          <TechnicalDetail>
            Example: Use TRNG to generate unique, one-time verification codes for multi-factor authentication.
          </TechnicalDetail>
        </UseCaseCard>

        <CalloutBox>
          <CalloutText>Discover how HavocHive’s entropy solutions enhance security across diverse applications, from finance to AI, to ensure optimal performance and safety.</CalloutText>
          <CTAButton href="enterprise-solutions">Learn More About Our Use Cases</CTAButton>
        </CalloutBox>
      </UseCasesWrapper>
    </UseCasesSection>
  );
};

export default UseCases;
