// src/pages/LoginPage.js
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import axios from 'axios';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const LoginPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  background-color: #ffffff;
  padding-top: 100px;
  padding-bottom: 100px;

  @media (max-width: 768px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 500px;
  background-color: #f9f9f9;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    padding: 1.5rem;
  }
`;

const TabHeader = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-top: 1rem; /* Add margin-top for phone screen sizes */
  }
`;

const TabButton = styled.button`
  flex: 1;
  padding: 0.75rem;
  background-color: ${({ $active }) => ($active ? '#ff5757' : '#eeeeee')};
  color: ${({ $active }) => ($active ? '#ffffff' : '#333333')};
  border: none;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;

  &:not(:last-child) {
    margin-right: 1rem;
  }

  &:hover {
    background-color: ${({ $active }) => ($active ? '#e04e4e' : '#dddddd')};
  }

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 0.5rem;
    width: 100%;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const InputField = styled.input`
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-size: 1rem;

  &:focus {
    border-color: #ff5757;
    outline: none;
  }
`;

const LoginButton = styled(motion.button)`
  padding: 0.75rem;
  background-color: #ff5757;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  box-shadow: 0px 0px 10px rgba(255, 87, 87, 0.3);
  margin-bottom: 1rem;

  &:hover {
    background-color: #e04e4e;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Link = styled.a`
  color: #ff5757;
  text-decoration: none;
  font-size: 0.9rem;

  &:hover {
    text-decoration: underline;
  }
`;

const UserInfo = styled.div`
  margin-top: 2rem;
  font-family: 'Courier New', monospace;
  font-size: 0.85rem;
  color: #555555;
  text-align: left;
  background-color: #f7f7f7;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;

  @media (max-width: 768px) {
    font-size: 0.75rem;
    padding: 0.75rem;
  }
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
  border-bottom: 1px solid #ddd;

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem 0;
  }
`;

const InfoLabel = styled.span`
  font-weight: bold;
  color: #555;
`;

const InfoValue = styled.span`
  color: #000;
  display: flex;
  align-items: center;
  gap: 5px;

  @media (max-width: 768px) {
    margin-top: 0.5rem;
  }
`;

const FlagImage = styled.img`
  width: 20px;
  height: auto;
`;

const LoginPage = () => {
  const [activeTab, setActiveTab] = useState('enterprise');
  const [userInfo, setUserInfo] = useState({
    ip: 'Fetching...',
    device: 'Fetching...',
    location: 'Fetching...',
    countryFlag: '',
    isp: 'Unavailable',
    threatLevel: 'Unknown',
    isProxy: 'Unknown',
    isVPN: 'Unknown',
    isTor: 'Unknown',
    currentTime: 'Unavailable',
  });

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const ipResponse = await axios.get('https://api64.ipify.org?format=json');
        const userIp = ipResponse.data.ip;
        console.log('User IP fetched:', userIp);

        const apiKey = process.env.REACT_APP_IPSTACK_API_KEY;
        const response = await axios.get(`https://api.ipstack.com/${userIp}?access_key=${apiKey}`);
        console.log('Location data fetched:', response.data);

        const ip = response.data.ip || 'N/A';
        const location = `${response.data.city || 'N/A'}, ${response.data.region_name || 'N/A'}, ${response.data.country_name || 'N/A'}`;
        const countryFlag = response.data.location?.country_flag || '';
        const isp = response.data.connection?.isp || 'Unavailable';
        const threatLevel = response.data.security?.threat_level || 'Unknown';
        const isProxy = response.data.security?.is_proxy ? 'Yes' : 'No';
        const isVPN = response.data.security?.vpn_service ? 'Yes' : 'No';
        const isTor = response.data.security?.is_tor ? 'Yes' : 'No';
        const currentTime = response.data.time_zone?.current_time || 'Unavailable';

        const device = navigator.userAgent;

        setUserInfo({
          ip,
          device,
          location,
          countryFlag,
          isp,
          threatLevel,
          isProxy,
          isVPN,
          isTor,
          currentTime,
        });
      } catch (error) {
        console.error('Error fetching user info:', error);
        setUserInfo({
          ip: 'Unable to fetch IP',
          device: 'Unable to fetch device information',
          location: 'Unable to fetch location',
          countryFlag: '',
          isp: 'Unavailable',
          threatLevel: 'Unavailable',
          isProxy: 'Unknown',
          isVPN: 'Unknown',
          isTor: 'Unknown',
          currentTime: 'Unavailable',
        });
      }
    };

    fetchUserInfo();
  }, []);

  return (
    <>
      <Navbar />
      <LoginPageContainer>
        <Wrapper>
          <TabHeader>
            <TabButton
              $active={activeTab === 'enterprise'}
              onClick={() => setActiveTab('enterprise')}
            >
              Enterprise Clients
            </TabButton>
            <TabButton
              $active={activeTab === 'contributor'}
              onClick={() => setActiveTab('contributor')}
            >
              Havoc Contributors
            </TabButton>
          </TabHeader>
          <Form>
            <InputField type="email" placeholder="Email Address" required />
            <InputField type="password" placeholder="Password" required />
            <LoginButton
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Login
            </LoginButton>
          </Form>
          <div>
            <Link href="/forgot-password">Forgot Password?</Link> |{' '}
            <Link href="/signup">Sign Up</Link>
          </div>
          <UserInfo>
            <InfoItem>
              <InfoLabel>When:</InfoLabel>
              <InfoValue>{new Date().toUTCString()}</InfoValue>
            </InfoItem>
            <InfoItem>
              <InfoLabel>IP Address:</InfoLabel>
              <InfoValue>{userInfo.ip}</InfoValue>
            </InfoItem>
            <InfoItem>
              <InfoLabel>Device:</InfoLabel>
              <InfoValue>{userInfo.device}</InfoValue>
            </InfoItem>
            <InfoItem>
              <InfoLabel>Location:</InfoLabel>
              <InfoValue>{userInfo.location}</InfoValue>
            </InfoItem>
            {userInfo.countryFlag && (
              <InfoItem>
                <InfoLabel>Country:</InfoLabel>
                <InfoValue>
                  <FlagImage src={userInfo.countryFlag} alt="Country Flag" />
                  {userInfo.location}
                </InfoValue>
              </InfoItem>
            )}
            <InfoItem>
              <InfoLabel>ISP:</InfoLabel>
              <InfoValue>{userInfo.isp}</InfoValue>
            </InfoItem>
            <InfoItem>
              <InfoLabel>Threat Level:</InfoLabel>
              <InfoValue>{userInfo.threatLevel}</InfoValue>
            </InfoItem>
            <InfoItem>
              <InfoLabel>Proxy:</InfoLabel>
              <InfoValue>{userInfo.isProxy}</InfoValue>
            </InfoItem>
            <InfoItem>
              <InfoLabel>VPN:</InfoLabel>
              <InfoValue>{userInfo.isVPN}</InfoValue>
            </InfoItem>
            <InfoItem>
              <InfoLabel>Tor:</InfoLabel>
              <InfoValue>{userInfo.isTor}</InfoValue>
            </InfoItem>
            <InfoItem>
              <InfoLabel>Current Time:</InfoLabel>
              <InfoValue>{userInfo.currentTime}</InfoValue>
            </InfoItem>
            <p><small>Location is approximate based on the login's IP address.</small></p>
          </UserInfo>
        </Wrapper>
      </LoginPageContainer>
      <Footer />
    </>
  );
};

export default LoginPage;
