// src/components/WhyHavocHive.js
import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faRandom, faExpand, faCogs, faLightbulb, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const WhyHavocHiveSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 4rem 1rem;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  color: #333333;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 2rem; /* Smaller title size on mobile */
  }
`;

const ChallengesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  max-width: 1200px;
  width: 100%;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr); /* Two columns on medium screens */
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* Single column on small screens */
  }
`;

const Challenge = styled.div`
  padding: 2.5rem;
  background-color: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 768px) {
    padding: 1.5rem; /* Reduce padding on small screens */
  }
`;

const SolutionChallenge = styled(Challenge)`
  background-color: #ff5757; /* Reddish background for the solution card */
  color: #ffffff; /* White text for contrast */
`;

const IconWrapper = styled.div`
  font-size: 3.5rem; /* Slightly larger icons */
  color: inherit; /* Inherit color based on card */
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    font-size: 2.5rem; /* Smaller icons on small screens */
  }
`;

const ChallengeTitle = styled.h3`
  font-size: 1.75rem;
  color: inherit; /* Inherit color based on card */
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 1.5rem; /* Smaller title on mobile */
  }
`;

const ChallengeDescription = styled.p`
  font-size: 1.125rem;
  color: #555555;
  line-height: 1.6;
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    font-size: 1rem; /* Smaller description on mobile */
  }
`;

const SolutionDescription = styled(ChallengeDescription)`
  color: #ffffff; /* White text for solution description */
`;

const TechnicalDetail = styled.p`
  font-size: 0.875rem;
  color: #777777;
  line-height: 1.4;
  margin-top: 0.5rem;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 0.8rem; /* Smaller text on mobile */
  }
`;

const SolutionTechnicalDetail = styled(TechnicalDetail)`
  color: #ffffff; /* White text for solution technical details */
`;

const WhyHavocHive = () => {
  return (
    <WhyHavocHiveSection id="why-havoc">
      <SectionTitle>Why HavocHive?</SectionTitle>
      <ChallengesWrapper>
        <Challenge>
          <IconWrapper>
            <FontAwesomeIcon icon={faShieldAlt} />
          </IconWrapper>
          <ChallengeTitle>Insufficient Entropy</ChallengeTitle>
          <ChallengeDescription>
            Traditional systems often struggle to generate enough entropy, particularly in high-demand environments, leading to weak cryptographic keys and reduced security.
          </ChallengeDescription>
          <TechnicalDetail>
            Problem: Low entropy sources can result in predictable keys, making systems vulnerable to brute-force attacks. This is a critical issue in environments where the quality of randomness directly impacts security.
          </TechnicalDetail>
        </Challenge>

        <Challenge>
          <IconWrapper>
            <FontAwesomeIcon icon={faRandom} />
          </IconWrapper>
          <ChallengeTitle>Predictability</ChallengeTitle>
          <ChallengeDescription>
            Inadequate randomness in cryptographic operations can lead to predictable outputs, allowing attackers to anticipate outcomes and compromise systems.
          </ChallengeDescription>
          <TechnicalDetail>
            Problem: Predictable random numbers undermine encryption schemes, digital signatures, and other cryptographic protocols. Attackers can exploit this predictability to reverse-engineer keys or predict security tokens.
          </TechnicalDetail>
        </Challenge>

        <Challenge>
          <IconWrapper>
            <FontAwesomeIcon icon={faExpand} />
          </IconWrapper>
          <ChallengeTitle>Scalability Issues</ChallengeTitle>
          <ChallengeDescription>
            Many entropy generation solutions fail to scale effectively, limiting their usability in large, distributed systems that require consistent, high-quality entropy.
          </ChallengeDescription>
          <TechnicalDetail>
            Problem: As systems grow, the need for reliable entropy increases. Traditional methods often fail to keep pace, leading to bottlenecks and degraded security in scalable environments like cloud services and blockchain networks.
          </TechnicalDetail>
        </Challenge>

        <Challenge>
          <IconWrapper>
            <FontAwesomeIcon icon={faCogs} />
          </IconWrapper>
          <ChallengeTitle>Complex Integration</ChallengeTitle>
          <ChallengeDescription>
            Integrating robust entropy sources into existing systems is often a complex, costly, and time-consuming process, requiring significant expertise and resources.
          </ChallengeDescription>
          <TechnicalDetail>
            Problem: Enterprises face high integration costs and delays when implementing secure entropy solutions, often needing to overhaul existing infrastructures or develop custom solutions.
          </TechnicalDetail>
        </Challenge>

        <Challenge>
          <IconWrapper>
            <FontAwesomeIcon icon={faExclamationTriangle} />
          </IconWrapper>
          <ChallengeTitle>Vulnerabilities in Current Solutions</ChallengeTitle>
          <ChallengeDescription>
            Existing entropy solutions often have known vulnerabilities that can be exploited by attackers, leading to compromised security.
          </ChallengeDescription>
          <TechnicalDetail>
            Problem: Many current entropy generators have been shown to have weaknesses that attackers can exploit, particularly in high-stakes environments like financial services and defense.
          </TechnicalDetail>
        </Challenge>

        <SolutionChallenge>
          <IconWrapper>
            <FontAwesomeIcon icon={faLightbulb} />
          </IconWrapper>
          <ChallengeTitle>HavocHive's Solution</ChallengeTitle>
          <SolutionDescription>
            HavocHive addresses these challenges by offering a scalable, high-quality entropy solution that is easy to integrate, enhances security, and is resilient to attacks.
          </SolutionDescription>
          <SolutionTechnicalDetail>
            Solution: HavocHive’s platform uses a novel approach to generate high-quality entropy, combining diverse data sources with advanced algorithms to produce truly random numbers that meet and exceed industry standards.
          </SolutionTechnicalDetail>
          <SolutionTechnicalDetail>
            Benefit: Enterprises can seamlessly integrate HavocHive into their existing infrastructures, ensuring robust security without the need for costly overhauls or extensive reconfigurations.
          </SolutionTechnicalDetail>
        </SolutionChallenge>
      </ChallengesWrapper>
    </WhyHavocHiveSection>
  );
};

export default WhyHavocHive;
