// src/components/HowItWorks.js
import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase, faCog, faGift, faBuilding, faHandshake, faFileContract } from '@fortawesome/free-solid-svg-icons';

const HowItWorksSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8f8; /* Light grey background for contrast */
  padding: 4rem 1rem; /* Adjusted padding for more responsive spacing */
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  color: #333333;
  margin-bottom: 2rem;
  text-align: center; /* Center text for all screen sizes */
`;

const LegendWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin: 0 1rem;
  margin-bottom: 1rem; /* Add bottom margin for spacing */
`;

const LegendColorBox = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${({ color }) => color};
  margin-right: 0.5rem;
  border: 1px solid #333333; /* Add a thin border to make it stand out */
`;

const LegendText = styled.span`
  font-size: 1rem;
  color: #333333;
`;

const ProcessWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  max-width: 1200px;
  width: 100%;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr); /* Adjust to 2 columns for smaller screens */
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* Stacks all steps in one column for phones */
  }
`;

const ProcessStep = styled.div`
  padding: 2rem;
  text-align: center;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 768px) {
    padding: 1.5rem; /* Reduced padding for smaller screens */
  }
`;

const ContributorStep = styled(ProcessStep)`
  background-color: #e8f7ff; /* Slightly different background for contributors */
`;

const EnterpriseStep = styled(ProcessStep)`
  background-color: #fff8e8; /* Slightly different background for enterprises */
`;

const IconWrapper = styled.div`
  font-size: 3rem; /* Reduced size for better scaling */
  color: #ff5757;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 2.5rem; /* Smaller icon size for phones */
  }
`;

const StepTitle = styled.h3`
  font-size: 1.5rem;
  color: #ff5757;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 1.3rem; /* Smaller title size for phones */
  }
`;

const StepDescription = styled.p`
  font-size: 1rem;
  color: #555555;
  line-height: 1.6;

  @media (max-width: 768px) {
    font-size: 0.9rem; /* Smaller description size for phones */
  }
`;

const TechnicalDetail = styled.p`
  font-size: 0.875rem;
  color: #777777;
  line-height: 1.4;
  margin-top: 0.5rem;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 0.75rem; /* Smaller technical detail size for phones */
  }
`;

const HowItWorks = () => {
  return (
    <HowItWorksSection id="how-it-works">
      <SectionTitle>How It Works</SectionTitle>
      
      <LegendWrapper>
        <LegendItem>
          <LegendColorBox color="#e8f7ff" />
          <LegendText>Contributor PoV</LegendText>
        </LegendItem>
        <LegendItem>
          <LegendColorBox color="#fff8e8" />
          <LegendText>Enterprise PoV</LegendText>
        </LegendItem>
      </LegendWrapper>

      <ProcessWrapper>
        <ContributorStep>
          <IconWrapper>
            <FontAwesomeIcon icon={faDatabase} />
          </IconWrapper>
          <StepTitle>Data Collection</StepTitle>
          <StepDescription>
            We gather diverse sensor data from thousands of contributors worldwide, ensuring a robust and chaotic data pool.
          </StepDescription>
          <TechnicalDetail>
            Details: The data collected includes sensor readings from devices like accelerometers, GPS units, and more. By diversifying data sources, we create a highly unpredictable and random pool of information, essential for generating strong entropy.
          </TechnicalDetail>
        </ContributorStep>

        <ContributorStep>
          <IconWrapper>
            <FontAwesomeIcon icon={faCog} />
          </IconWrapper>
          <StepTitle>Entropy Generation</StepTitle>
          <StepDescription>
            Using advanced algorithms, we transform raw data into high-quality entropy, essential for secure cryptographic operations.
          </StepDescription>
          <TechnicalDetail>
            Details: Our entropy generation process involves sophisticated algorithms that apply chaotic maps and hashing techniques to raw data, ensuring that the output is random and unpredictable, meeting the highest standards of cryptographic security.
          </TechnicalDetail>
        </ContributorStep>

        <ContributorStep>
          <IconWrapper>
            <FontAwesomeIcon icon={faGift} />
          </IconWrapper>
          <StepTitle>Contributor Rewards</StepTitle>
          <StepDescription>
            Contributors earn Havoc Tokens on Solana for their data, directly sent to their wallets each month.
          </StepDescription>
          <TechnicalDetail>
            Details: Contributors are rewarded in Havoc Tokens, which are issued on the Solana blockchain for fast, low-cost transactions. This incentivizes continuous data contributions, ensuring a steady flow of high-quality entropy.
          </TechnicalDetail>
        </ContributorStep>

        <EnterpriseStep>
          <IconWrapper>
            <FontAwesomeIcon icon={faHandshake} />
          </IconWrapper>
          <StepTitle>Initial Consultation</StepTitle>
          <StepDescription>
            Enterprises start with an in-depth consultation to assess their specific security needs and how HavocHive can enhance their infrastructure.
          </StepDescription>
          <TechnicalDetail>
            Details: During the consultation, our experts evaluate your current systems, identify vulnerabilities, and propose customized solutions leveraging our advanced entropy services.
          </TechnicalDetail>
        </EnterpriseStep>

        <EnterpriseStep>
          <IconWrapper>
            <FontAwesomeIcon icon={faFileContract} />
          </IconWrapper>
          <StepTitle>Sign and Subscribe to a Plan (SLA)</StepTitle>
          <StepDescription>
            Enterprises subscribe to a service level agreement (SLA) that defines the scope, quality, and speed of entropy services tailored to their needs.
          </StepDescription>
          <TechnicalDetail>
            Details: Our flexible SLA options allow enterprises to choose the level of service that matches their operational requirements, ensuring predictable costs and guaranteed service performance.
          </TechnicalDetail>
        </EnterpriseStep>

        <EnterpriseStep>
          <IconWrapper>
            <FontAwesomeIcon icon={faBuilding} />
          </IconWrapper>
          <StepTitle>Enterprise Integration</StepTitle>
          <StepDescription>
            Enterprises can easily integrate our entropy service into their systems, enhancing security and randomness.
          </StepDescription>
          <TechnicalDetail>
            Details: Our APIs and SDKs are designed for seamless integration into various enterprise systems, allowing businesses to incorporate high-quality entropy into their security infrastructure without significant reconfiguration or downtime.
          </TechnicalDetail>
        </EnterpriseStep>
      </ProcessWrapper>
    </HowItWorksSection>
  );
};

export default HowItWorks;
