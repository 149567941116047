// src/components/ForEnterprises.js
import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faCogs, faExpand, faKey, faCloud, faBolt } from '@fortawesome/free-solid-svg-icons';
import Footer from './Footer';

const ForEnterprisesSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8f8;
  padding: 10rem 1rem;

  @media (max-width: 768px) {
    padding: 5rem 1rem; /* Adjust padding for smaller screens */
  }
`;

const BrandWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; /* Allows wrapping for smaller screens */
`;

const BrandLogo = styled.img`
  height: 9rem;
  margin-right: 1rem;

  @media (max-width: 768px) {
    height: 6rem; /* Adjust logo size for smaller screens */
    margin-right: 0.5rem;
  }
`;

const BrandName = styled.div`
  font-size: 12rem;
  font-weight: bold;
  display: flex;
  opacity: 0;
  transform: translateY(-20px);
  animation: fadeInUp 1s forwards;

  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (max-width: 768px) {
    font-size: 6rem; /* Adjust title size for smaller screens */
  }

  @media (max-width: 480px) {
    font-size: 4rem; /* Further adjust title size for extra small screens */
  }
`;

const HavocText = styled.span`
  color: #ff5757;
`;

const HiveText = styled.span`
  color: #000000;
`;

const SectionTitle = styled.h2`
  font-size: 3.5rem;
  color: #333333;
  margin-bottom: 1.5rem;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 2.5rem; /* Adjust title size for smaller screens */
  }

  @media (max-width: 480px) {
    font-size: 2rem; /* Further adjust title size for extra small screens */
  }
`;

const SubHeading = styled.h3`
  font-size: 1.2rem;
  color: #555555;
  margin-bottom: 2rem;
  text-align: center;
  max-width: 800px;

  @media (max-width: 768px) {
    font-size: 1rem; /* Adjust font size for smaller screens */
  }

  @media (max-width: 480px) {
    font-size: 0.9rem; /* Further adjust font size for extra small screens */
  }
`;

const HighlightedText = styled.span`
  color: #161616;
  font-weight: bold;
`;

const Paragraph = styled.p`
  font-size: 1.125rem;
  color: #2D2D2D;
  line-height: 1.7;
  margin-bottom: 2rem;
  max-width: 1200px;
  text-align: center;
  margin: 0 auto;

  @media (max-width: 768px) {
    font-size: 1rem; /* Adjust font size for smaller screens */
  }

  @media (max-width: 480px) {
    font-size: 0.9rem; /* Further adjust font size for extra small screens */
  }
`;

const SectionBox = styled.div`
  background-color: #ffffff;
  border: 2px solid #ff5757;
  border-radius: 10px;
  padding: 2rem;
  margin: 2rem 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  max-width: 1200px;
  text-align: center;

  @media (max-width: 768px) {
    padding: 1.5rem; /* Adjust padding for smaller screens */
  }
`;

const SectionHeading = styled.h3`
  font-size: 2.5rem;
  color: #333333;
  margin-bottom: 0.5rem;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 2rem; /* Adjust heading size for smaller screens */
  }

  @media (max-width: 480px) {
    font-size: 1.75rem; /* Further adjust heading size for extra small screens */
  }
`;

const SectionSubHeading = styled.h4`
  font-size: 1.5rem;
  color: #555555;
  margin-bottom: 1rem;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.25rem; /* Adjust subheading size for smaller screens */
  }

  @media (max-width: 480px) {
    font-size: 1rem; /* Further adjust subheading size for extra small screens */
  }
`;

const KPIWrapper = styled.div`
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin: 2.5rem 0;
  padding: 1rem;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr); /* Adjust to 2 columns on medium screens */
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* Adjust to 1 column on smaller screens */
  }
`;

const KPITile = styled.div`
  background-color: #f7f7f7;
  border-left: 4px solid #ff5757;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  font-family: 'Courier New', monospace;
  height: 300px; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 768px) {
    height: auto; /* Adjust height for smaller screens */
  }
`;

const KpiLabel = styled.h4`
  font-size: 1rem;
  color: #555555;
  margin-bottom: 0.25rem;

  @media (max-width: 768px) {
    font-size: 0.9rem; /* Adjust font size for smaller screens */
  }

  @media (max-width: 480px) {
    font-size: 0.8rem; /* Further adjust font size for extra small screens */
  }
`;

const KpiValue = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
  color: #ff5757;

  @media (max-width: 768px) {
    font-size: 1.25rem; /* Adjust font size for smaller screens */
  }

  @media (max-width: 480px) {
    font-size: 1rem; /* Further adjust font size for extra small screens */
  }
`;

const TableWrapper = styled.div`
  overflow-x: auto;
  margin: 3rem 0;
  max-width: 1200px;
`;

const TableTitle = styled.h4`
  font-size: 1.25rem;
  color: #333333;
  margin-bottom: 1rem;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.1rem; /* Adjust font size for smaller screens */
  }

  @media (max-width: 480px) {
    font-size: 1rem; /* Further adjust font size for extra small screens */
  }
`;

const StyledTable = styled.table`
  width: 100%; /* Full width for all screens */
  border-collapse: collapse;
  margin-bottom: 2rem;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    font-size: 0.9rem; /* Adjust font size for smaller screens */
  }

  @media (max-width: 480px) {
    font-size: 0.8rem; /* Further adjust font size for extra small screens */
  }
`;

const TableHeader = styled.th`
  background-color: #ff5757;
  color: #ffffff;
  padding: 1rem;
  text-align: left;
  font-size: 1rem;

  @media (max-width: 768px) {
    padding: 0.75rem; /* Reduce padding for smaller screens */
  }
`;

const TableCell = styled.td`
  padding: 1rem;
  border-bottom: 1px solid #eeeeee;
  color: #555555;
  font-size: 1rem;

  @media (max-width: 768px) {
    padding: 0.75rem; /* Reduce padding for smaller screens */
  }
`;

const EnterprisesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 1200px;
  width: 100%;
  margin-bottom: 4rem;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr); /* Adjust to 2 columns on medium screens */
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* Adjust to 1 column on smaller screens */
  }
`;

const EnterpriseCard = styled.div`
  padding: 2.5rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 768px) {
    padding: 1.5rem; /* Adjust padding for smaller screens */
  }
`;

const IconWrapper = styled.div`
  font-size: 3.5rem;
  color: #ff5757;
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    font-size: 2.5rem; /* Adjust icon size for smaller screens */
  }
`;

const CardTitle = styled.h3`
  font-size: 1.75rem;
  color: #333333;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 1.5rem; /* Adjust title size for smaller screens */
  }
`;

const CardDescription = styled.p`
  font-size: 1.125rem;
  color: #555555;
  line-height: 1.6;
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    font-size: 1rem; /* Adjust description size for smaller screens */
  }
`;

const TechnicalDetail = styled.p`
  font-size: 0.875rem;
  color: #777777;
  line-height: 1.4;
  margin-top: 0.5rem;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 0.75rem; /* Adjust technical detail size for smaller screens */
  }
`;

const CalloutBox = styled.div`
  grid-column: span 3;
  padding: 3rem 2rem;
  background-color: #ff5757;
  color: #ffffff;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  max-width: 1200px;
  margin: 3rem auto;

  @media (max-width: 768px) {
    grid-column: span 1; /* Adjust column span for smaller screens */
    padding: 2rem; /* Adjust padding for smaller screens */
  }
`;

const CalloutText = styled.p`
  font-size: 1.5rem;
  line-height: 1.7;
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    font-size: 1.25rem; /* Adjust text size for smaller screens */
  }
`;

const CTAButton = styled.a`
  display: inline-block;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: #ffffff;
  color: #ff5757;
  text-decoration: none;
  border-radius: 50px;
  box-shadow: 0px 0px 10px rgba(255, 87, 87, 0.3);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background-color: #e04e4e;
    box-shadow: 0px 0px 15px rgba(255, 87, 87, 0.5);
  }

  @media (max-width: 768px) {
    padding: 0.5rem 1rem; /* Adjust button size for smaller screens */
    font-size: 0.9rem; /* Adjust font size for smaller screens */
  }
`;

const ForEnterprises = () => {
  return (
    <>
      <ForEnterprisesSection id="for-enterprises">
        {/* Brand and Logo Section */}
        <BrandWrapper>
          <BrandLogo src="/HavocHivelogo.svg" alt="HavocHive Logo" />
          <BrandName>
            <HavocText>Havoc</HavocText>
            <HiveText>Hive</HiveText>
          </BrandName>
        </BrandWrapper>

        <SectionTitle>Enterprises, SMEs, and Startups</SectionTitle>
        <SubHeading>
          Empower your business with <HighlightedText>high-quality entropy</HighlightedText>. Discover how HavocHive can secure and scale your digital infrastructure effortlessly.
        </SubHeading>

        <CalloutBox>
          <CalloutText>Building the next-generation security product or crafting a groundbreaking smart contract-enabled web3 solution? HavocHive equips you with the highest quality entropy, a key component to safeguard your innovation. Our comprehensive APIs and SDKs make integration seamless, while our scalable solutions ensure your security keeps pace with your growth, helping you stay one step ahead.
          </CalloutText>
        </CalloutBox>

        {/* Entropy Quality Snapshot Section */}
        <SectionBox>
          <SectionHeading>Entropy Quality Snapshot</SectionHeading>
          <SectionSubHeading>Typical KPIs for our Entropy Streams</SectionSubHeading>
          <Paragraph>
            The following metrics represent a snapshot of the quality of entropy generated by HavocHive, demonstrating the robustness and reliability of our random data for critical security applications.
          </Paragraph>
          <KPIWrapper>
            <KPITile>
              <KpiLabel>Entropy Quality</KpiLabel>
              <KpiValue>7.952113 bits/byte</KpiValue>
              <Paragraph>
                Measures the randomness or uncertainty in the entropy source. A value close to 8 bits per byte indicates high-quality entropy, crucial for secure cryptographic operations.
              </Paragraph>
            </KPITile>
            <KPITile>
              <KpiLabel>Compression Efficiency</KpiLabel>
              <KpiValue>0% reduction</KpiValue>
              <Paragraph>
                Indicates the effectiveness of compressing the entropy data. A 0% reduction shows that the data cannot be compressed further, confirming maximum randomness.
              </Paragraph>
            </KPITile>
            <KPITile>
              <KpiLabel>Chi-square</KpiLabel>
              <KpiValue>226.76 (89.79%)</KpiValue>
              <Paragraph>
                The Chi-square test checks how well the generated random data fits the expected distribution, suggesting a uniform distribution.
              </Paragraph>
            </KPITile>
            <KPITile>
              <KpiLabel>Arithmetic Mean</KpiLabel>
              <KpiValue>127.5517 (random)</KpiValue>
              <Paragraph>
                The arithmetic mean value is a benchmark for truly random data, indicating that the entropy source produces values evenly spread across the range. 127 = fully random.
              </Paragraph>
            </KPITile>
            <KPITile>
              <KpiLabel>Monte Carlo for Pi</KpiLabel>
              <KpiValue>3.1877 (1.47% error)</KpiValue>
              <Paragraph>
                Represents the approximation of Pi derived from the random data, showing the effectiveness in producing truly random outcomes.
              </Paragraph>
            </KPITile>
            <KPITile>
              <KpiLabel>Serial Correlation</KpiLabel>
              <KpiValue>-0.011201 (uncorrelated)</KpiValue>
              <Paragraph>
                Measures the degree to which consecutive values are related. A value close to 0 indicates no predictable pattern.
              </Paragraph>
            </KPITile>
          </KPIWrapper>
        </SectionBox>

        {/* Available Formats and Sizes for TRNG and DRBG Services Section */}
        <SectionBox>
          <SectionHeading>Formats and Sizes for TRNG and DRBG</SectionHeading>
          <SectionSubHeading>Flexibility and Choice for Your Security Needs</SectionSubHeading>
          <Paragraph>
            HavocHive offers a variety of sizes and formats for True Random Number Generation (TRNG) and Deterministic Random Bit Generation (DRBG) services, ensuring you have the flexibility to meet any cryptographic requirement.
          </Paragraph>
          <TableWrapper>
            <TableTitle>Available Sizes and Formats for TRNG and Entropy Services</TableTitle>
            
            <StyledTable>
              <thead>
                <tr>
                  <TableHeader>Size (Bits)</TableHeader>
                  <TableHeader>Format</TableHeader>
                  <TableHeader>Use Case Examples</TableHeader>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <TableCell>16, 32, 64, 128, 256, 512, 1024, 2048, 4096</TableCell>
                  <TableCell>Hexadecimal</TableCell>
                  <TableCell>Cryptographic keys (e.g., AES, RSA), Blockchain wallet seeds</TableCell>
                </tr>
                <tr>
                  <TableCell>16, 32, 64, 128, 256, 512, 1024, 2048, 4096</TableCell>
                  <TableCell>Binary</TableCell>
                  <TableCell>Secure communication protocols, Random bit masking</TableCell>
                </tr>
                <tr>
                  <TableCell>16, 32, 64, 128, 256, 512, 1024, 2048, 4096</TableCell>
                  <TableCell>Base64</TableCell>
                  <TableCell>Secure API tokens, Nonce generation for secure web sessions</TableCell>
                </tr>
              </tbody>
            </StyledTable>
          </TableWrapper>

          <TableWrapper>
            <TableTitle>Available Sizes and Formats for DRBG Services</TableTitle>
            
            <StyledTable>
              <thead>
                <tr>
                  <TableHeader>DRBG Type</TableHeader>
                  <TableHeader>Size (Bits)</TableHeader>
                  <TableHeader>Format</TableHeader>
                  <TableHeader>Use Case Examples</TableHeader>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <TableCell>Hash DRBG</TableCell>
                  <TableCell>16, 32, 64, 128, 256, 512, 1024, 2048, 4096</TableCell>
                  <TableCell>Hexadecimal</TableCell>
                  <TableCell>Digital signatures, Encryption schemes</TableCell>
                </tr>
                <tr>
                  <TableCell>HMAC DRBG</TableCell>
                  <TableCell>16, 32, 64, 128, 256, 512, 1024, 2048, 4096</TableCell>
                  <TableCell>Binary</TableCell>
                  <TableCell>Token generation, Secure communications</TableCell>
                </tr>
                <tr>
                  <TableCell>CTR DRBG</TableCell>
                  <TableCell>16, 32, 64, 128, 256, 512, 1024, 2048, 4096</TableCell>
                  <TableCell>Base64</TableCell>
                  <TableCell>Blockchain consensus algorithms, Random beacon services</TableCell>
                </tr>
              </tbody>
            </StyledTable>
          </TableWrapper>
        </SectionBox>

        <EnterprisesWrapper>
          <EnterpriseCard>
            <IconWrapper>
              <FontAwesomeIcon icon={faShieldAlt} />
            </IconWrapper>
            <CardTitle>Unrivaled Entropy for Enterprise-Grade Security</CardTitle>
            <CardDescription>
              HavocHive delivers the highest quality entropy, ensuring the security of your cryptographic operations and the integrity of your data.
            </CardDescription>
            <TechnicalDetail>
              Example: Achieve nearly perfect randomness, quantum resistance, and unmatched flexibility in your security solutions.
            </TechnicalDetail>
          </EnterpriseCard>

          <EnterpriseCard>
            <IconWrapper>
              <FontAwesomeIcon icon={faCogs} />
            </IconWrapper>
            <CardTitle>Integration Made Simple</CardTitle>
            <CardDescription>
              Integrate HavocHive entropy into your existing infrastructure with ease. Our APIs, SDKs, and documentation ensure seamless integration.
            </CardDescription>
            <TechnicalDetail>
              Example: Deploy high-quality entropy services in cloud or on-premises environments with minimal reconfiguration.
            </TechnicalDetail>
          </EnterpriseCard>

          <EnterpriseCard>
            <IconWrapper>
              <FontAwesomeIcon icon={faExpand} />
            </IconWrapper>
            <CardTitle>Unparalleled Scalability</CardTitle>
            <CardDescription>
              As your business grows, so do your needs. HavocHive scales effortlessly, providing consistent performance regardless of demand.
            </CardDescription>
            <TechnicalDetail>
              Example: Seamless scaling from startups to Fortune 500 companies, ensuring your security infrastructure keeps pace with your growth.
            </TechnicalDetail>
          </EnterpriseCard>

          <EnterpriseCard>
            <IconWrapper>
              <FontAwesomeIcon icon={faKey} />
            </IconWrapper>
            <CardTitle>Advanced Encryption</CardTitle>
            <CardDescription>
              Employ industry-leading encryption standards with 256-bit security, protecting your enterprise from potential threats.
            </CardDescription>
            <TechnicalDetail>
              Example: Secure communications and data with 256-bit encryption, ensuring uncompromised security across all operations.
            </TechnicalDetail>
          </EnterpriseCard>

          <EnterpriseCard>
            <IconWrapper>
              <FontAwesomeIcon icon={faCloud} />
            </IconWrapper>
            <CardTitle>Cloud Security</CardTitle>
            <CardDescription>
              Protect your cloud infrastructure with HavocHive’s high-entropy solutions, ensuring secure and unpredictable operations.
            </CardDescription>
            <TechnicalDetail>
              Example: Dynamic VM initialization using unpredictable entropy to thwart side-channel attacks.
            </TechnicalDetail>
          </EnterpriseCard>

          <EnterpriseCard>
            <IconWrapper>
              <FontAwesomeIcon icon={faBolt} />
            </IconWrapper>
            <CardTitle>High Performance</CardTitle>
            <CardDescription>
              HavocHive delivers entropy at speeds that outpace industry standards, ensuring top performance even under heavy loads.
            </CardDescription>
            <TechnicalDetail>
              Example: 2x faster entropy generation than industry average, supporting high-throughput cryptographic operations.
            </TechnicalDetail>
          </EnterpriseCard>
        </EnterprisesWrapper>

        <CalloutBox>
          <CalloutText>HavocHive’s entropy passes the <HighlightedText>NIST SP800-90B</HighlightedText> tests for IID and Non-IID sources, and our RNG solutions have been rigorously tested against the <HighlightedText>NIST SP800-22</HighlightedText> battery of tests.</CalloutText>
          <CTAButton href="/request-info">Get in Touch</CTAButton>
        </CalloutBox>
      </ForEnterprisesSection>
      
      <Footer />
    </>
  );
};

export default ForEnterprises;
