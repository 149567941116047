// src/components/ForContributors.js
import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserShield, faKey, faWallet, faLock, faTrashAlt, faHandsHelping } from '@fortawesome/free-solid-svg-icons';

const ForContributorsSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 4rem 1rem;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  color: #333333;
  margin-bottom: 2rem;
  text-align: center;
`;

const ContributorsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  max-width: 1200px; 
  width: 100%;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr); /* Adjust to 2 columns on medium screens */
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* Adjust to 1 column on smaller screens */
  }
`;

const ContributorCard = styled.div`
  padding: 2.5rem;
  background-color: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 768px) {
    padding: 1.5rem; /* Adjust padding for smaller screens */
  }
`;

const IconWrapper = styled.div`
  font-size: 3.5rem;
  color: #333333; /* Black color for icons to match styling */
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    font-size: 2.5rem; /* Adjust icon size for smaller screens */
  }
`;

const CardTitle = styled.h3`
  font-size: 1.75rem;
  color: #333333;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 1.5rem; /* Adjust title size for smaller screens */
  }
`;

const CardDescription = styled.p`
  font-size: 1.125rem;
  color: #555555;
  line-height: 1.6;
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    font-size: 1rem; /* Adjust description size for smaller screens */
  }
`;

const TechnicalDetail = styled.p`
  font-size: 0.875rem;
  color: #777777;
  line-height: 1.4;
  margin-top: 0.5rem;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 0.75rem; /* Adjust technical detail size for smaller screens */
  }
`;

const CalloutBox = styled.div`
  grid-column: span 3; 
  padding: 2.5rem;
  background-color: #ff5757; 
  color: #ffffff; 
  border-radius: 8px;
  text-align: center;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);

  @media (max-width: 768px) {
    grid-column: span 1; /* Adjust column span for smaller screens */
    padding: 1.5rem; /* Adjust padding for smaller screens */
  }
`;

const CalloutText = styled.p`
  font-size: 1.5rem;
  line-height: 1.7;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 1.25rem; /* Adjust text size for smaller screens */
  }
`;

const CTAButton = styled.a`
  display: inline-block;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: #ffffff;
  color: #ff5757;
  text-decoration: none;
  border-radius: 50px;
  box-shadow: 0px 0px 10px rgba(255, 87, 87, 0.3);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background-color: #e04e4e;
    box-shadow: 0px 0px 15px rgba(255, 87, 87, 0.5);
  }

  @media (max-width: 768px) {
    font-size: 0.9rem; /* Adjust button text size for smaller screens */
    padding: 0.5rem 1rem; /* Adjust padding for smaller screens */
  }
`;

const ForContributors = () => {
  return (
    <ForContributorsSection id ="for-contributors">
      <SectionTitle>For Contributors</SectionTitle>
      <ContributorsWrapper>
        <ContributorCard>
          <IconWrapper>
            <FontAwesomeIcon icon={faUserShield} />
          </IconWrapper>
          <CardTitle>Secure Data Contribution</CardTitle>
          <CardDescription>
            Contribute anonymized sensor data to help create high-quality entropy that secures encryption algorithms and blockchain networks.
          </CardDescription>
          <TechnicalDetail>
            Example: Your data enhances entropy used in cryptographic key generation, reducing the risk of brute-force attacks.
          </TechnicalDetail>
        </ContributorCard>

        <ContributorCard>
          <IconWrapper>
            <FontAwesomeIcon icon={faKey} />
          </IconWrapper>
          <CardTitle>Privacy Protection</CardTitle>
          <CardDescription>
            We ensure that no Personally Identifiable Information (PII) is collected—only anonymized sensor data is used to enhance security.
          </CardDescription>
          <TechnicalDetail>
            Example: GPS data and accelerometer readings are anonymized and stripped of any identifying information before use.
          </TechnicalDetail>
        </ContributorCard>

        <ContributorCard>
          <IconWrapper>
            <FontAwesomeIcon icon={faWallet} />
          </IconWrapper>
          <CardTitle>Earn Havoc Tokens</CardTitle>
          <CardDescription>
            Get rewarded in Havoc Tokens for your data contributions, with payments directly sent to your Solana wallet.
          </CardDescription>
          <TechnicalDetail>
            Example: Regular monthly payouts based on the volume and quality of the data you contribute.
          </TechnicalDetail>
        </ContributorCard>

        <ContributorCard>
          <IconWrapper>
            <FontAwesomeIcon icon={faLock} />
          </IconWrapper>
          <CardTitle>Your Data, Your Control</CardTitle>
          <CardDescription>
            Retain full control over your contributions with our 1-click delete option that ensures immediate removal with no retention.
          </CardDescription>
          <TechnicalDetail>
            Example: Delete your data with a single click, ensuring it is purged from our systems immediately.
          </TechnicalDetail>
        </ContributorCard>

        <ContributorCard>
          <IconWrapper>
            <FontAwesomeIcon icon={faTrashAlt} />
          </IconWrapper>
          <CardTitle>Fresh and Unpredictable Entropy</CardTitle>
          <CardDescription>
            We constantly purge our data lakes to maintain a fresh and unpredictable entropy pool, eliminating patterns and enhancing security.
          </CardDescription>
          <TechnicalDetail>
            Example: Regular data purges to remove stale information, ensuring the entropy pool remains dynamic and secure.
          </TechnicalDetail>
        </ContributorCard>

        <ContributorCard>
          <IconWrapper>
            <FontAwesomeIcon icon={faHandsHelping} />
          </IconWrapper>
          <CardTitle>Join a Global Effort</CardTitle>
          <CardDescription>
            Be part of a global network of contributors, collectively enhancing the security of digital systems worldwide.
          </CardDescription>
          <TechnicalDetail>
            Example: Your contribution helps secure systems across industries, from finance to healthcare, ensuring global data protection.
          </TechnicalDetail>
        </ContributorCard>

        <CalloutBox>
          <CalloutText>Your chaos helps secure the world, and you get paid for it.</CalloutText>
          <CTAButton href="/whitepaper">Read Our Whitepaper</CTAButton>
        </CalloutBox>
      </ContributorsWrapper>
    </ForContributorsSection>
  );
};

export default ForContributors;
