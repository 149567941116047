// src/pages/RequestInfoPage.js
import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import axios from 'axios';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
  background-color: #f8f8f8;
  padding: 5rem 1rem 0 1rem;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  background-color: #ffffff;
  border: 2px solid #ff5757;
  border-radius: 8px;
  padding: 3rem;
  margin-bottom: 20px;
  margin-top: 100px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 2rem;
  }
`;

const BrandWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  flex-wrap: wrap; /* Allows the content to wrap on smaller screens */
`;

const BrandLogo = styled.img`
  height: 9rem;
  margin-right: 1rem;

  @media (max-width: 768px) {
    height: 6rem;
    margin-right: 0.5rem;
  }

  @media (max-width: 480px) {
    height: 4rem; /* Adjust logo size for extra small screens */
    margin-right: 0.3rem;
  }
`;

const BrandName = styled.div`
  font-size: 8rem;
  font-weight: bold;
  display: flex;
  opacity: 0;
  transform: translateY(-20px);
  animation: fadeInUp 1s forwards;
  white-space: nowrap; /* Prevents text from breaking into multiple lines */

  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (max-width: 768px) {
    font-size: 5rem;
  }

  @media (max-width: 480px) {
    font-size: 3rem; /* Further reduce font size for smaller screens */
  }
`;

const HavocText = styled.span`
  color: #ff5757;
`;

const HiveText = styled.span`
  color: #000000;
`;

const HeaderSection = styled.div`
  text-align: center;
  margin-bottom: 30px;

  h2 {
    font-size: 2.5rem;
    color: #ff5757;
    margin-bottom: 10px;
  }

  p {
    font-size: 1.1rem;
    color: #444;
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 1.8rem;
    }
    p {
      font-size: 1rem;
    }
  }
`;

const FormWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const InputField = styled.input`
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-size: 1rem;

  &:focus {
    border-color: #ff5757;
    outline: none;
  }
`;

const TextArea = styled.textarea`
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-size: 1rem;
  resize: vertical;

  &:focus {
    border-color: #ff5757;
    outline: none;
  }
`;

const SelectField = styled.select`
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-size: 1rem;

  &:focus {
    border-color: #ff5757;
    outline: none;
  }
`;

const SubmitButton = styled(motion.button)`
  padding: 0.75rem;
  background-color: #ff5757;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  box-shadow: 0px 0px 10px rgba(255, 87, 87, 0.3);
  margin-bottom: 1rem;

  &:hover {
    background-color: #e04e4e;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  max-width: 500px;
  text-align: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
`;

const ModalButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: #ff5757;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 1rem;

  &:hover {
    background-color: #e04e4e;
  }
`;

const RequestInfoPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    industry: '',
    service: '',
    entropySize: '',
    message: '',
  });

  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('/email/send-email.php', formData, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      });

      if (response.data.success) {
        setModalMessage('Thank you for reaching out! Your inquiry has been sent successfully. You will be redirected to the home page in a few seconds.');
      } else {
        setModalMessage(`Failed to send your inquiry: ${response.data.message}`);
      }

      setShowModal(true);
      setTimeout(() => {
        window.location.href = 'https://havochive.com';
      }, 3000);
    } catch (error) {
      console.error('Error sending inquiry:', error);
      setModalMessage('There was an issue sending your inquiry. Please try again later.');
      setShowModal(true);
    }
  };

  return (
    <>
      <Navbar />
      <PageContainer>
        <ContentWrapper>
          <BrandWrapper>
            <BrandLogo src="/HavocHivelogo.svg" alt="HavocHive Logo" />
            <BrandName>
              <HavocText>Havoc</HavocText>
              <HiveText>Hive</HiveText>
            </BrandName>
          </BrandWrapper>

          <HeaderSection>
            <h2>Contact Us</h2>
            <p>
              Please use the form below to get in touch with HavocHive. Provide as much detail as possible to help us understand your needs, and we'll respond with information on how we can provide enterprise-grade entropy or True Random Number generation for your business.
            </p>
          </HeaderSection>

          <FormWrapper>
            <Form onSubmit={handleSubmit}>
              <InputField
                type="text"
                name="name"
                placeholder="Your Name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <InputField
                type="email"
                name="email"
                placeholder="Your Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <InputField
                type="text"
                name="company"
                placeholder="Company Name"
                value={formData.company}
                onChange={handleChange}
                required
              />
              <SelectField
                name="industry"
                value={formData.industry}
                onChange={handleChange}
                required
              >
                <option value="">Select Your Industry</option>
                <option value="Finance">Finance</option>
                <option value="Healthcare">Healthcare</option>
                <option value="Technology">Technology</option>
                <option value="Government">Government</option>
                <option value="Education">Education</option>
                <option value="Retail">Retail</option>
                <option value="Other">Other</option>
              </SelectField>
              <SelectField
                name="service"
                value={formData.service}
                onChange={handleChange}
                required
              >
                <option value="">Select Service of Interest</option>
                <option value="Entropy Generation">Entropy Generation</option>
                <option value="True Random Number Generation">True Random Number Generation</option>
                <option value="NIST DRBG">NIST DRBG</option>
              </SelectField>
              <SelectField
                name="entropySize"
                value={formData.entropySize}
                onChange={handleChange}
                required
              >
                <option value="">Select Entropy Size</option>
                <option value="128 bits">128 bits</option>
                <option value="256 bits">256 bits</option>
                <option value="512 bits">512 bits</option>
                <option value="1024 bits">1024 bits</option>
                <option value="2048 bits">2048 bits</option>
                <option value="4096 bits">4096 bits</option>
              </SelectField>
              <TextArea
                name="message"
                placeholder="Additional Details"
                rows="6"
                value={formData.message}
                onChange={handleChange}
                required
              />
              <SubmitButton
                type="submit"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Send Inquiry
              </SubmitButton>
            </Form>
          </FormWrapper>
        </ContentWrapper>
      </PageContainer>

      {showModal && (
        <Modal>
          <ModalContent>
            <p>{modalMessage}</p>
            <ModalButton onClick={() => setShowModal(false)}>Close</ModalButton>
          </ModalContent>
        </Modal>
      )}

      <Footer />
    </>
  );
};

export default RequestInfoPage;
