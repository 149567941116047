// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './styles/GlobalStyle';
import theme from './styles/theme';
import Hero from './components/Hero';
import HowItWorks from './components/HowItWorks';
import WhyHavocHive from './components/WhyHavocHive';
import UseCases from './components/UseCases';
import ForContributors from './components/ForContributors';
import ForEnterprises from './components/ForEnterprises';
import HavocToken from './components/HavocToken';
import LoginPage from './pages/LoginPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import Disclaimer from './pages/Disclaimer';
import Whitepaper from './pages/Whitepaper';
import EnterpriseSolutions from './components/EnterpriseSolutions';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import RequestForInfoPage from './pages/RequestInfoPage'; // Import the new page

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Router>
        <Navbar />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Hero />
                <HowItWorks />
                <WhyHavocHive />
                <UseCases />
                <ForContributors />
                <ForEnterprises />
                <HavocToken />
                <Footer />
              </>
            }
          />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/whitepaper" element={<Whitepaper />} />
          <Route path="/enterprise-solutions" element={<EnterpriseSolutions />} />
          <Route path="/request-info" element={<RequestForInfoPage />} /> {/* Add new route */}
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
