// src/components/ForEnterprises.js
import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faCogs, faExpand, faKey, faCloud, faBolt } from '@fortawesome/free-solid-svg-icons';

const ForEnterprisesSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8f8;
  padding: 4rem 1rem;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  color: #333333;
  margin-bottom: 2rem;
  text-align: center;
`;

const HighlightedText = styled.span`
  color: #1E1D1D; /* Specific shade of red */
  font-weight: bold; /* Bold for emphasis */
`;

const EnterprisesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  max-width: 1200px;
  width: 100%;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr); /* Adjust to 2 columns on medium screens */
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* Adjust to 1 column on smaller screens */
  }
`;

const EnterpriseCard = styled.div`
  padding: 2.5rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 768px) {
    padding: 1.5rem; /* Adjust padding for smaller screens */
  }
`;

const IconWrapper = styled.div`
  font-size: 3.5rem;
  color: #ff5757; /* Reddish color for icons */
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    font-size: 2.5rem; /* Adjust icon size for smaller screens */
  }
`;

const CardTitle = styled.h3`
  font-size: 1.75rem;
  color: #333333;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 1.5rem; /* Adjust title size for smaller screens */
  }
`;

const CardDescription = styled.p`
  font-size: 1.125rem;
  color: #555555;
  line-height: 1.6;
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    font-size: 1rem; /* Adjust description size for smaller screens */
  }
`;

const TechnicalDetail = styled.p`
  font-size: 0.875rem;
  color: #777777;
  line-height: 1.4;
  margin-top: 0.5rem;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 0.75rem; /* Adjust technical detail size for smaller screens */
  }
`;

const CalloutBox = styled.div`
  grid-column: span 3;
  padding: 2.5rem;
  background-color: #ff5757;
  color: #ffffff;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);

  @media (max-width: 768px) {
    grid-column: span 1; /* Adjust column span for smaller screens */
    padding: 1.5rem; /* Adjust padding for smaller screens */
  }
`;

const CalloutText = styled.p`
  font-size: 1.5rem;
  line-height: 1.7;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 1.25rem; /* Adjust text size for smaller screens */
  }
`;

const CTAButton = styled.a`
  display: inline-block;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: #ffffff;
  color: #ff5757;
  text-decoration: none;
  border-radius: 50px;
  box-shadow: 0px 0px 10px rgba(255, 87, 87, 0.3);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background-color: #e04e4e;
    box-shadow: 0px 0px 15px rgba(255, 87, 87, 0.5);
  }

  @media (max-width: 768px) {
    font-size: 0.9rem; /* Adjust button text size for smaller screens */
    padding: 0.5rem 1rem; /* Adjust padding for smaller screens */
  }
`;

const ForEnterprises = () => {
  return (
    <ForEnterprisesSection id="for-enterprises">
      <SectionTitle>For Enterprises</SectionTitle>
      <EnterprisesWrapper>
        <EnterpriseCard>
          <IconWrapper>
            <FontAwesomeIcon icon={faShieldAlt} />
          </IconWrapper>
          <CardTitle>Unrivaled Entropy for Enterprise-Grade Security</CardTitle>
          <CardDescription>
            HavocHive delivers the highest quality entropy, ensuring the security of your cryptographic operations and the integrity of your data.
          </CardDescription>
          <TechnicalDetail>
            Example: Achieve nearly perfect randomness, quantum resistance, and unmatched flexibility in your security solutions.
          </TechnicalDetail>
        </EnterpriseCard>

        {/* Repeat similar blocks for other enterprise benefits */}
        <EnterpriseCard>
          <IconWrapper>
            <FontAwesomeIcon icon={faCogs} />
          </IconWrapper>
          <CardTitle>Integration Made Simple</CardTitle>
          <CardDescription>
            Integrate HavocHive entropy into your existing infrastructure with ease. Our APIs, SDKs, and documentation ensure seamless integration.
          </CardDescription>
          <TechnicalDetail>
            Example: Deploy high-quality entropy services in cloud or on-premises environments with minimal reconfiguration.
          </TechnicalDetail>
        </EnterpriseCard>

        <EnterpriseCard>
          <IconWrapper>
            <FontAwesomeIcon icon={faExpand} />
          </IconWrapper>
          <CardTitle>Unparalleled Scalability</CardTitle>
          <CardDescription>
            As your business grows, so do your needs. HavocHive scales effortlessly, providing consistent performance regardless of demand.
          </CardDescription>
          <TechnicalDetail>
            Example: Seamless scaling from startups to Fortune 500 companies, ensuring your security infrastructure keeps pace with your growth.
          </TechnicalDetail>
        </EnterpriseCard>

        <EnterpriseCard>
          <IconWrapper>
            <FontAwesomeIcon icon={faKey} />
          </IconWrapper>
          <CardTitle>Advanced Encryption</CardTitle>
          <CardDescription>
            Employ industry-leading encryption standards with 256-bit security, protecting your enterprise from potential threats.
          </CardDescription>
          <TechnicalDetail>
            Example: Secure communications and data with 256-bit encryption, ensuring uncompromised security across all operations.
          </TechnicalDetail>
        </EnterpriseCard>

        <EnterpriseCard>
          <IconWrapper>
            <FontAwesomeIcon icon={faCloud} />
          </IconWrapper>
          <CardTitle>Cloud Security</CardTitle>
          <CardDescription>
            Protect your cloud infrastructure with HavocHive’s high-entropy solutions, ensuring secure and unpredictable operations.
          </CardDescription>
          <TechnicalDetail>
            Example: Dynamic VM initialization using unpredictable entropy to thwart side-channel attacks.
          </TechnicalDetail>
        </EnterpriseCard>

        <EnterpriseCard>
          <IconWrapper>
            <FontAwesomeIcon icon={faBolt} />
          </IconWrapper>
          <CardTitle>High Performance</CardTitle>
          <CardDescription>
            HavocHive delivers entropy at speeds that outpace industry standards, ensuring top performance even under heavy loads.
          </CardDescription>
          <TechnicalDetail>
            Example: 2x faster entropy generation than industry average, supporting high-throughput cryptographic operations.
          </TechnicalDetail>
        </EnterpriseCard>

        <CalloutBox>
          <CalloutText>HavocHive’s entropy passes the <HighlightedText>NIST SP800-90B</HighlightedText> tests for IID and Non-IID sources, and our RNG solutions have been rigorously tested against the <HighlightedText>NIST SP800-22</HighlightedText> battery of tests.</CalloutText>
          <CTAButton href="/enterprise-solutions">Explore Enterprise Solutions</CTAButton>
        </CalloutBox>
      </EnterprisesWrapper>
    </ForEnterprisesSection>
  );
};

export default ForEnterprises;
