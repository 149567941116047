// src/pages/Disclaimer.js
import React from 'react';
import Navbar from '../components/Navbar'; // Import the Navbar component
import Footer from '../components/Footer'; // Import the Footer component
import styled from 'styled-components';

const PageContainer = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8f8;
  margin-top: 6rem; /* Add margin-top to provide spacing from the Navbar */

  @media (max-width: 768px) {
    padding: 1.5rem;
    margin-top: 4rem;
  }
`;

const Card = styled.div`
  max-width: 1000px;
  width: 100%;
  background-color: #ffffff;
  padding: 3rem;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: justify;

  @media (max-width: 768px) {
    padding: 2rem;
  }
`;

const SectionTitle = styled.h1`
  font-size: 2.5rem;
  color: #333333;
  margin-bottom: 1rem;
  text-align: center; /* Center the title */

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Paragraph = styled.p`
  font-size: 1rem;
  color: #555555;
  line-height: 1.7;
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const Disclaimer = () => {
  return (
    <>
      <Navbar />
      <PageContainer>
        <Card>
          <SectionTitle>Disclaimer</SectionTitle>
          <Paragraph>
            The information provided on this website is for general informational purposes only.
            HavocHive makes no representations or warranties of any kind regarding the accuracy,
            reliability, or completeness of any information on this site.
          </Paragraph>
          <Paragraph>
            Any reliance you place on such information is strictly at your own risk. In no event
            will HavocHive be liable for any loss or damage, including without limitation, indirect
            or consequential loss or damage, or any loss or damage whatsoever arising from the use
            of this website.
          </Paragraph>
          <Paragraph>
            This website may contain links to third-party websites that are not controlled or
            maintained by HavocHive. We do not endorse these websites and are not responsible for
            their content or availability. Visiting such websites is at your own risk.
          </Paragraph>
          <Paragraph>
            The terms and conditions outlined in this disclaimer are subject to change at any time
            without prior notice. By using this website, you agree to the current version of this
            disclaimer.
          </Paragraph>
          {/* Add more content here as needed */}
        </Card>
      </PageContainer>
      <Footer />
    </>
  );
};

export default Disclaimer;
