// src/styles/theme.js
const theme = {
  colors: {
    primary: '#FF0000', // Red for buttons and highlights
    background: '#FFFFFF', // Light background
    text: '#333333', // Dark grey text for better readability
    heading: '#333333', // Dark grey for headings
    link: '#FF0000', // Red links
    linkHover: '#CC0000', // Darker red on hover
  },
  typography: {
    fontFamily: `'Roboto', sans-serif`,
    fontFamilyCode: `'Courier New', monospace`,
    fontWeightRegular: 400,
    fontWeightBold: 700,
  },
};

export default theme;
